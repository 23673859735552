import {getResourcesURL} from "./init"
import Log from "@wisetack/shared-ui/utils/Log";

export const appTransition = (e, {user, history, location, setMerchantUser, setError, logOut}) => {

    const navigate = (pathname) => {
        if (location && location.pathname === pathname) {
            return
        }
        if (history && pathname) {
            history.push(pathname);
        }
    }

    switch (e.alias) {
        case 'user_management':
            navigate("/user_management");
            break;
        case 'user':
            navigate("/user");
            break;
        case 'business':
            navigate("/business");
            break;
        case 'log_out':
            logOut();
            navigate("/");
            break;
        case 'prequal_history':
            navigate("/prequals");
            break;
        case 'transaction_history':
            navigate("/transactions");
            break;
        case 'new_transaction':
            navigate("/new_transaction");
            break;    
        case 'account':
            setMerchantUser && setMerchantUser(e.data)
            document.getElementById('menuToggleCheckbox').checked = false;
            break;    
        case 'account_settings':
            navigate("/account_settings");
            break;    
        case 'calculator':
            navigate("/calculator");
            break;
        case 'help':
            navigate("/help");
            break;
        case 'faq':
            window.open("https://www.wisetack.com/faqs");
            break;
        case 'resources':
            let url = getResourcesURL(user ? user.partnerAlias : '');
            window.open(url);
            break;
        default:
            setError && setError(`Sorry, [${e.alias}] not implemented yet.`)
            break;
    }
}

export const redirectToEntryPage = ({history}, msg) => {
    Log.error(msg)
    history.push("/");
}