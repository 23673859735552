import React from "react";
import classNames from "classnames";
import styles from "./CustomerInformation.module.scss";
import ownStyles from "./TransactionDetails.module.scss";
import {formatExpires} from "../utils/format";
import {getOfferInfo} from "../utils/offerInfo";

const totalRefunds = (item) => {
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    let refundsAmmount = 0;

    if (!item.refunds || item.refunds.length === 0)
        return null;

    item.refunds.forEach(refund => {
        refundsAmmount += parseFloat(refund.amount.replace(/[$|,]/g, ""));
    })
    return formatter.format(refundsAmmount);
}

export function TransactionDetails({item}) {
    const approved = item.status === "Started"
        ? "Pending Approval" : (item.approvedLoanAmount || "N/A")
    const qualified = item.maximumAmount || "N/A"
    const netProceeds = item.settledLoanAmount || "N/A"
    const expires = item.expiresAt ? `Expires ${formatExpires(item.expiresAt)}` : "Expires N/A"
    const refundsAmmount = totalRefunds(item)
    const offers = getOfferInfo(item.offers)
    const payouts = item.payoutDetailsList
    const hasPayouts = payouts && payouts.length > 1

    const cardHeaderClasses = classNames({
        "card-header": true,
        [styles.cardHeader]: true
    });

    const cardBodyClasses = classNames({
        "collapse show": true,
        [styles.cardBody]: true
    });

    return (
        <div className="card card-collapse">
            <div className={cardHeaderClasses}>
                <a
                    data-toggle="collapse"
                    aria-expanded="true"
                    href="#collapseTransaction"
                >
                    <div className={styles.header}>
                        Transaction details
                        <span>
                            <i className="material-icons">keyboard_arrow_down</i>
                        </span>
                    </div>
                </a>
            </div>
            <div id="collapseTransaction" className={cardBodyClasses}>
                <div className={classNames(styles.info)}>
                    <table width="100%">
                        <tbody>
                            <tr id="approved"
                                className={classNames(styles.info)}>
                                <td className={classNames(ownStyles.iconStatusStyle)}>
                                    <span className="material-icons-outlined">credit_card</span>
                                </td>
                                <td>
                                    Approved
                                </td>
                                <td>
                                    {approved}
                                </td>
                            </tr>
                            {hasPayouts && payouts.map((payout, index) => {
                                const payoutAmount = payout.approvedLoanAmount ? payout.approvedLoanAmount : payout.requestedLoanAmount;
                                const payoutAmountFormatted = `$${payoutAmount}`;
                                return (
                                    <tr id={`payout-${index}`}
                                        key={payout.payoutName + "#" + payout.statusChangedOn}
                                        className={classNames(styles.info)}>
                                        <td className={classNames(ownStyles.iconStatusStyle)}/>
                                        <td>
                                            {payout.payoutName}
                                        </td>
                                        <td>
                                            {payoutAmountFormatted}
                                        </td>
                                    </tr>
                                )
                            })}
                            <tr id="qualified" className={classNames(styles.info)}>
                                <td className={classNames(ownStyles.iconStatusStyle)}/>
                                <td>
                                    Qualified
                                </td>
                                <td>
                                    {qualified}
                                </td>
                            </tr>
                            <tr id="net-proceeds"
                                className={classNames(styles.info)}>
                                <td className={classNames(ownStyles.iconStatusStyle)}/>
                                <td>
                                    Net proceeds
                                </td>
                                <td>
                                    {netProceeds}
                                </td>
                            </tr>
                            {refundsAmmount && <tr id="refunded"
                                className={classNames(styles.info)}>
                                <td className={classNames(ownStyles.iconStatusStyle)}/>
                                <td>
                                    Refunded
                                </td>
                                <td>
                                    {refundsAmmount}
                                </td>
                            </tr>}
                            {offers && offers.terms && <tr id="offers"
                                className={classNames(styles.info)}>
                                <td>
                                    Offers available
                                </td>
                                <td>
                                    {offers.terms.apr}<br/>{offers.terms.months}
                                </td>
                            </tr>}
                            {offers && offers.terms && <tr id="processing-fee"
                                className={classNames(styles.info)}>
                                <td className={classNames(ownStyles.iconStatusStyle)}/>
                                <td>
                                    Processing fee
                                </td>
                                <td>
                                    {offers.mdrs}
                                </td>
                            </tr>}
                            <tr id="expires"
                                className={classNames(styles.info)}>
                                <td className={classNames(ownStyles.iconStatusStyle)}>
                                    <span className="material-icons-outlined">date_range</span>
                                </td>
                                <td>
                                    {expires}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}