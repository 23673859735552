import React from "react"
import classNames from "classnames";
import { connect } from "react-redux";
import uuid from "uuid";

import Container from "../components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import Form from "@wisetack/shared-ui/components/Form";
import FormRow from "@wisetack/shared-ui/components/FormRow";
import FormInput from "@wisetack/shared-ui/components/FormInput";
import LoaderWithMessage from "@wisetack/shared-ui/components/LoaderWithMessage";
import Error from "@wisetack/shared-ui/components/Error";
import { formatUsPhone as phoneFormat } from "@wisetack/shared-ui/utils/format";
import Log from "@wisetack/shared-ui/utils/Log";
import { MerchantFieldValidator } from "@wisetack/shared-ui/utils/MerchantFieldValidator";
import { logAmplitudeEvent } from "@wisetack/shared-ui/components/Amplitude";

import { submitUsername, setError } from "../store/actions/merchantActions";
import styles from "./LoginPage.module.scss";
import { stateFromProps } from "../utils/state";

const formatUsPhone = (value) => {
    if (value.match(/^[0-9-]+$/)) {
        return phoneFormat(value);
    }
    return value;
}

const formFields = {
    username: {
        label: 'Mobile number or email',
        inputMode: 'text',
        autoFill: '5555555555',
        autoFormat: formatUsPhone,
        validatorName: 'validateUsername'
    }
}

const stateNames = [
    "isLoading",
    "errorMessage",
    "merchantId",
    "submitUsernameRequestId"
]

const logProps = {}

class LoginPage extends React.Component {
    state = {
        username: "",
        fieldsChanged: {},
        errors: {},
        errorMessage: ""
    }

    constructor(props) {
        super(props);
        this.validator = new MerchantFieldValidator(this.pageName())
        for (const value of Object.values(formFields)) {
            if (value.validatorName) {
                value.validator = this.validator[value.validatorName];
            }
        }
    }

    pageName = () => {
        switch (this.getMode()) {
            case 'firstLogin':
                return "Merchant Login Page"
            case 'retryLogin':
                return "Merchant Login Retry Page"
            default:
                return ""
        }
    }

    getEditValue = name => {
        if (this.state.fieldsChanged[name] || this.state[name]) {
            return this.state[name];
        }
        if (this.props.fieldsValue[name]) {
            return this.props.fieldsValue[name];
        }
        return "";
    };

    formInputKeyPressed = (event) => {
        if (event.charCode !== 96) {
            return;
        }
        const requestId = uuid.v4();
        logAmplitudeEvent("Pressed Impersonation Flow Invocation Button (impersonation mode)", logProps);
        this.props.submitUsername(
            this.formatMobileNumber(this.state.username),
            requestId,
            true
        );
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        let pageName = this.pageName()
        logProps.page = pageName
        logAmplitudeEvent(pageName, logProps);
        this.validator.props = logProps;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return stateFromProps(nextProps, prevState, logProps)
    }

    componentDidUpdate(prevProps) {
        if (this.props.errorMessage && this.props.errorMessage.includes('lockout')) {
            this.props.setError(null);
            this.props.history.push("/account_locked");
            return;
        }
        if (this.props.submitUsernameRequestId && this.props.submitUsernameRequestId !== prevProps.submitUsernameRequestId) {
            if (this.props.errorMessage) {
                return;
            }
            logAmplitudeEvent("Phone number submitted", logProps);
            this.props.history.push("/pin");
        }
    }

    getMode = () => {
        if (this.props.location.pathname === '/') {
            return 'firstLogin'
        }
        if (this.props.location.pathname === '/login_retry') {
            return 'retryLogin'
        }
    }

    setError(name, error) {
        this.setState({
            errors: {
                ...this.state.errors,
                [name]: error
            }
        });
    }

    autoFill = (name, val) => {
        if (val === "~" && formFields[name].autoFill) {
            if (typeof formFields[name].autoFill === "function") {
                return formFields[name].autoFill();
            }
            return formFields[name].autoFill;
        }
        return val;
    };

    autoFormat = (name, val) => {
        if (!!val && typeof formFields[name].autoFormat === "function") {
            return formFields[name].autoFormat(val);
        }
        return val;
    }

    inputCleanUp = (name, val) => {
        if (val && formFields[name] && formFields[name].blacklist) {
            return val.replace(formFields[name].blacklist, "");
        }
        return val;
    }

    handleOnChange = e => {
        let val = e.target.value;
        let name = e.target.name;
        val = this.autoFill(name, val);
        val = this.inputCleanUp(name, val);
        val = this.autoFormat(name, val);
        this.setState({
            [name]: val,
            fieldsChanged: { ...this.state.fieldsChanged, [name]: true }
        });
        this.setError(name, "");
    };

    handleOnBlur = e => {
        let val = e.target.value;
        let name = e.target.name;
        const error = this.validateField(name, val);
        this.setError(name, error);
    };

    validateField = (name, val) => {
        if (typeof formFields[name].validator === "function") {
            if (formFields[name].suggestFn) {
                return formFields[name].validator(val, formFields[name].suggestFn.bind(this));
            } else {
                return formFields[name].validator(val);
            }
        }
        if (!val && !formFields[name].optional) {
            const message = formFields[name].mandatory || `Enter ${formFields[name].label}`
            this.validator.log(formFields[name].label, message);
            return message;
        }
        return "";
    };

    formatMobileNumber = mobileNumber => {
        mobileNumber = mobileNumber.replace(/\D/g, "");
        if (mobileNumber.length === 10) {
            return "+1" + mobileNumber;
        } else if (mobileNumber.length === 11) {
            return "+" + mobileNumber;
        }
        return mobileNumber;
    };

    handleSubmit = e => {
        const requestId = uuid.v4();
        logAmplitudeEvent("Pressed Send Code Button", logProps);
        if (this.state.username.includes('@')) {
            this.props.submitUsername(
                this.state.username.trim(),
                requestId,
                false
            );
        } else {
            this.props.submitUsername(
                this.formatMobileNumber(this.state.username),
                requestId,
                false
            );
        }
    };

    isButtonDisabled = () => {
        if (this.props.isLoading) return true;
        for (const key in this.state.errors) {
            if (this.state.errors[key]) return true;
        }
        for (const [name, value] of Object.entries(formFields)) {
            if (!value.optional && !this.state[name]) {
                return true;
            }
        }
        return false;
    };

    render() {
        Log.info({ state: this.state, props: this.props }, `render state/props`);

        let btnDisabled = this.isButtonDisabled();

        const btnClasses = classNames({
            btn: true,
            "btn-block": true,
            "btn-disabled": btnDisabled,
            [styles.buttonDisabled]: btnDisabled,
            [styles.buttonEnabled]: !btnDisabled
        });

        const formInput = (fieldName) => {
            return (
                <FormInput
                    name={fieldName}
                    label={formFields[fieldName].label}
                    value={this.getEditValue(fieldName)}
                    onChange={this.handleOnChange}
                    onBlur={this.handleOnBlur}
                    errors={this.state.errors}
                    inputMode={formFields[fieldName].inputMode}
                    pattern={formFields[fieldName].pattern}
                    innerRef={formFields[fieldName].innerRef}
                    fieldsError={this.state.fieldsError}
                    onKeyPress={this.formInputKeyPressed}
                />
            )
        }

        const HeaderContent = () => {
            switch (this.getMode()) {
                case 'firstLogin':
                    return <PageHeader progress="">
                        <div>Sign in to your account</div>
                        <div style={{ textAlign: "center", marginBottom: "30px" }}>Enter your mobile number or email associated with your merchant&nbsp;account.</div>
                    </PageHeader>
                case 'retryLogin':
                    return <PageHeader progress="">
                        <div>Let's try verifying your phone number or email again</div>
                        <div>
                            <div>Enter the email or phone number where you can receive text messages.</div>
                            <ul>
                                <li>Must be associated with your Wisetack account.</li>
                                <li>Check your Wisetack merchant portal email invitation for account details.</li>
                            </ul>
                        </div>
                    </PageHeader>
                default:
                    return <PageHeader progress="">
                        <div></div>
                        <div></div>
                    </PageHeader>
            }
        }

        const SubmitButton = () => {
            return (
                <div className="row" style={{ marginTop: "20px" }}>
                    <div className="col">
                        <button className={btnClasses} data-test-id="send_code_button" onClick={this.handleSubmit}>
                            <span className="material-icons" style={{ fontSize: "16px", marginRight: "5px", paddingBottom: "3px" }}>
                                lock_outline
                            </span>
                            SEND CODE
                        </button>
                    </div>
                </div>
            )
        }

        const Message = () => {
            if (this.getMode() !== 'firstLogin') {
                return null;
            }
            return (
                <div className={styles.message}>We'll send you a verification code.</div>
            )
        }

        return (
            <Container>
                <HeaderContent />
                {
                    !this.props.isLoading &&
                    <>
                        <Form>
                            <FormRow>
                                {formInput("username")}
                            </FormRow>
                        </Form>
                        <Error pageName={this.pageName()}>{this.state.errorMessage}</Error>
                        {SubmitButton()}
                        {Message()}
                    </>
                }
                <LoaderWithMessage loading={this.props.isLoading} />
            </Container>
        )
    }
}

const setPropFromState = (props, state, name, path) => {
    if (path) {
        state = state.merchant[path];
    } else {
        state = state.merchant
    }
    if (state) {
        props[name] = state[name];
    }
};

const mapStateToProps = (state) => {
    let props = {
        fieldsError: {},
        fieldsValue: {}
    };
    stateNames.forEach(name => setPropFromState(props, state, name));
    return props;
};

export default connect(
    mapStateToProps,
    { submitUsername, setError }
)(LoginPage);