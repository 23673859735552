import React, {Fragment} from "react";
import classNames from "classnames";
import styles from "./CustomerInformation.module.scss";
import ownStyles from "./LoanStatusHistory.module.scss";
import moment from "moment";

export function LoanStatusHistory({ item }) {
    const payouts = item.payoutDetailsList;
    const hasPayouts = payouts && payouts.length > 1;

    const cardHeaderClasses = classNames({
        "card-header": true,
        [styles.cardHeader]: true
    });

    const cardBodyClasses = classNames({
        "collapse": true,
        [styles.cardBody]: true
    });

    const formatDate = (dateStr) => {
        if(!dateStr || dateStr.length < 10) {
            return '- -'
        }
        dateStr = dateStr.substring(0, 10)
        return moment(dateStr,"YYYY-MM-DD").format("MMM DD, YYYY")
    }

    const iconStatusStyle = {
        paddingRight: 0,
        width: "1%"
    };

    const payoutNameStyle = {
        fontWeight: 600,
        color: "#4F4F4F"
    };

    const tdToRight = {
        textAlign: "right",
        paddingRight: "0px"
    };

    return (
        <div className="card card-collapse">
            <div className={cardHeaderClasses}>
                <a
                    data-toggle="collapse"
                    href="#collapseLoanStatusHistory"
                >
                    <div className={styles.header}>
                        Loan status
                        <span>
                            <i className="material-icons">keyboard_arrow_down</i>
                        </span>
                    </div>
                </a>
            </div>
            <div id="collapseLoanStatusHistory" className={cardBodyClasses}>
                <div className={classNames(ownStyles.status, styles.info)}>
                    <table width="100%">
                        <tbody>
                        {hasPayouts && payouts.map((payout, index) => {
                            return (
                                <Fragment key={payout.payoutName + "#" + payout.statusChangedOn}>
                                    <tr id={`payout-${index}-name`}
                                        className={classNames(ownStyles.status, styles.info)}>
                                        <td style={iconStatusStyle}>
                                            {index === 0 && <span className="material-icons-outlined">history</span>}
                                        </td>
                                        <td style={payoutNameStyle}>
                                            {payout.payoutName}
                                        </td>
                                        <td/>
                                    </tr>
                                    <tr id={`payout-${index}-status`}
                                        className={classNames(ownStyles.status, styles.info)}>
                                        <td/>
                                        <td>
                                            {payout.statusName}
                                        </td>
                                        <td>
                                            {payout.statusChangedOn ?
                                                formatDate(payout.statusChangedOn)
                                                : formatDate(item.statusChangedOn)
                                            }
                                        </td>
                                    </tr>
                                </Fragment>
                            )
                        })}
                        {!hasPayouts &&
                            <tr id="status" className={classNames(ownStyles.status, styles.info)}>
                                <td style={iconStatusStyle}>
                                    <span className="material-icons-outlined">history</span>
                                </td>
                                <td>
                                    {item.status}
                                </td>
                                <td style = {tdToRight}>
                                    {formatDate(item.statusChangedOn)}
                                </td>
                            </tr>
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
