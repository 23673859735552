import React from "react";
import styles from "./UserEditPage.module.scss"
import FormInput from "@wisetack/shared-ui/components/FormInput";
import Container from "../components/Container";
import NavBar from "../components/NavBar";
import FormRow from "@wisetack/shared-ui/components/FormRow";
import ButtonFilled from "../components/ButtonFilled";
import {connect} from "react-redux";
import {getMerchant, logOut, setError, setMerchantUser, updateUser} from "../store/actions/merchantActions";
import {formatUsPhoneToDomestic, formatMobileNumberForApi} from "@wisetack/shared-ui/utils/format";
import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";
import {appTransition, redirectToEntryPage} from "../utils/transitions";
import {stateFromProps} from "../utils/state";
import {verifyWisetackToken} from "../utils/auth";
import {UserFieldValidator} from "../utils/UserFieldValidator";
import LoaderWithMessage from "@wisetack/shared-ui/components/LoaderWithMessage";
import info from "@wisetack/assets/img/info-icon.svg";
import Modal from "@wisetack/shared-ui/components/Modal";
import checkbox_v from "@wisetack/assets/img/checkbox_v.svg";
import checkbox from "@wisetack/assets/img/checkbox.svg";
import edit from "../assets/img/edit.svg"
import ErrorWithIcon from "@wisetack/shared-ui/components/ErrorWithIcon";

const merchantStateNames = [
    "isLoading",
    "users",
    "merchant",
    "user",
    "errorMessage"
]

const settingsStateNames = [
    "fieldUpdateStatus",
    "userUpdateRequestId"
]

const updatedFieldToAmplitudeEventMapping = {
    phoneNumber: "Verify New Phone Page",
    email: "Verify New Email Page"
}

const pageName = "User information edit page"
const logProps = {page: pageName}
const validator = new UserFieldValidator(pageName);

const formFields = {
    firstName: {
        label: 'First name',
        pattern: '^[ -~]*$',
        stateName: 'firstNameEncrypted',
        innerRef: React.createRef(),
        formatForPayload: v => v,
        validatorName: 'validateFirstName'
    },
    lastName: {
        label: 'Last name',
        pattern: '^[ -~]*$',
        stateName: 'lastNameEncrypted',
        innerRef: React.createRef(),
        formatForPayload: v => v,
        validatorName: 'validateLastName'
    },
    phoneNumber: {
        label: 'Mobile phone number',
        inputMode: 'numeric',
        pattern: '[0-9]*',
        autoFormat: formatUsPhoneToDomestic,
        formatForPayload: formatMobileNumberForApi,
        validatorName: 'validateMobileNumber',
        hintLabel: 'You\'ll receive a PIN code to this phone number for verification.',
        verificationTitle: 'Let\'s verify your new number',
        stateName: 'phoneNumberEncrypted',
        innerRef: React.createRef()
    },
    email: {
        label: 'Email address',
        validatorName: 'validateEmail',
        suggestFn: function (suggestion) {
            this.setState({suggestions: {email: suggestion}});
        },
        formatForPayload: v => v,
        hintLabel: 'You\'ll receive a PIN code to this email for verification.',
        stateName: 'emailEncrypted',
        innerRef: React.createRef(),
        verificationTitle: 'Let\'s verify your new email address'
    },
    subscribed: {
        label: 'Email notification setting',
        stateName: 'subscribed',
        formatForPayload: v => v,
        optional: true,
        checkboxText: 'Receive all loan application updates and weekly transaction reports.'
    }
}

const setValidators = (props) => {
    validator.props = props
    for (const value of Object.values(formFields)) {
        if (value.validatorName) {
            value.validator = validator[value.validatorName];
        }
    }
}

const MODE = {
    INPUT: 'INPUT',
    VERIFICATION: 'VERIFICATION'
}

class UserEditPage extends React.Component {

    state = {
        originalValues: {
            firstName: this.props.firstNameEncrypted,
            lastName: this.props.lastNameEncrypted,
            phoneNumber: formatUsPhoneToDomestic(this.props.phoneNumberEncrypted),
            email: this.props.emailEncrypted,
            subscribed: !!this.props.subscribed,
        },
        errors: {},
        firstName: this.props.firstNameEncrypted,
        lastName: this.props.lastNameEncrypted,
        phoneNumber: formatUsPhoneToDomestic(this.props.phoneNumberEncrypted),
        email: this.props.emailEncrypted,
        subscribed: !!this.props.subscribed,
        mode: MODE.INPUT,
        accessCode: '',
        showHintMessageFor: {},
        showEditButtonFor: Object.keys(formFields)
            .reduce((acc, key) => {return {...acc, [key]: true}}, {}),
        showSaveButtonFor: '',
        accessCodeRequired: false,
    }

    showEditButtonFor = (inputName, display) => {
        this.setState({
            showEditButtonFor: {
                ...this.state.showEditButtonFor,
                [inputName]: display
            }
        })
    }

    showHintMessageFor = (fieldName, showMessage) => {
        this.setState(
            {
                showHintMessageFor: {
                    [fieldName]: showMessage
                }
            }
        )
    }

    handleOnPenClick = e => {
        let name = e.target.name;
        let innerRef = formFields[name].innerRef;
        if (innerRef) {
            innerRef.current.focus();
        }
        this.showHintMessageFor(name, true)
        this.showEditButtonFor(name, false)

        const clickLogProps = {
            ...logProps,
            fieldName: name
        }
        logAmplitudeEvent("Pressed edit button", clickLogProps);
    }

    handleOnFocus = e => {
        let name = e.target.name;
        this.showHintMessageFor(name, true)
        if (this.wasModified(name)) {
            this.showSaveButtonFor(name, true)
        }
        this.showEditButtonFor(name, false)
        const clickLogProps = {
            ...logProps,
            fieldName: name
        }
        logAmplitudeEvent("Pressed edit button", clickLogProps);
    }

    preparePayload = () => {
        for (const [name, value] of Object.entries(formFields)) {
            if (this.state.showSaveButtonFor === name) {
                return {
                    [name]: value.formatForPayload(this.state[name])
                }
            }
        }
    }

    handleSave = () => {
        const merchantId = this.props.user.merchantId
        const userId = this.props.user.userId

        const payload = this.preparePayload()

        this.props.updateUser(merchantId, userId, payload)

        this.setState({
            accessCodeRequired: payload['phoneNumber'] || payload['email']
        })

        if (!this.isCurrentMode(MODE.VERIFICATION) && this.state.accessCodeRequired) {

            const updatedFieldName = Object.keys(payload)[0];

            if (updatedFieldName) {
                const amplitudeEvent = updatedFieldToAmplitudeEventMapping[updatedFieldName];
                if (amplitudeEvent) {
                    const verifyPageProps = {
                        ...logProps,
                        page: null
                    }
                    logAmplitudeEvent(amplitudeEvent, verifyPageProps)
                }
            }
        }
    }

    switchToMode = (mode) => {
        this.setState({mode: mode})
        this.props.setError(null)
    }

    isCurrentMode = (mode) => {
        return this.state.mode === mode;
    }

    wasModified = (inputName) => {

        const originalValue = this.state.originalValues[inputName];
        const actualValue = this.state[inputName];

        return originalValue !== actualValue
    }

    inputCleanUp = (name, val) => {
        if (val && formFields[name] && formFields[name].blacklist) {
            return val.replace(formFields[name].blacklist, "");
        }
        return val;
    }

    autoFormat = (name, val) => {
        if (!!val && typeof formFields[name].autoFormat === "function") {
            return formFields[name].autoFormat(val);
        }
        return val;
    }

    handleOnChange = e => {
        let val = e.target.value;
        let name = e.target.name;

        val = this.inputCleanUp(name, val)
        val = this.autoFormat(name, val)

        this.setState({
            [name]: val
        });
        this.setError(name, "");

        const wasModified = this.state.originalValues[name] !== val;

        if (wasModified) {
            this.showSaveButtonFor(name, true)
        } else {
            this.showSaveButtonFor(name, false)
        }
    };

    handleOnAccessCodeChange = e => {
        let val = e.target.value
        let name = e.target.name

        val = val.replace(/\D/g, "")
        if (val.length > 4) {
            return;
        }

        this.setState({accessCode: val})
        this.setError(name, "")
        if (val.length === 4) {
            const merchantId = this.props.user.merchantId
            const userId = this.props.user.userId
    
            const payload = this.preparePayload()
            if (this.state.showSaveButtonFor === 'phoneNumber') {
                payload['phoneUpdateAccessCode'] = val
            } else if (this.state.showSaveButtonFor === 'email') {
                payload['emailUpdateAccessCode'] = val
            }
    
            this.props.updateUser(merchantId, userId, payload)
        }
    }

    validateField = (name, val) => {
        if (typeof formFields[name].validator === "function") {
            if (formFields[name].suggestFn) {
                return formFields[name].validator(val, formFields[name].suggestFn.bind(this));
            } else {
                return formFields[name].validator(val);
            }
        }
        if (!val && !formFields[name].optional) {
            const message = formFields[name].mandatory || `Enter ${formFields[name].label}`
            validator.log(formFields[name].label, message);
            return message;
        }
        return "";
    };

    handleMenuItemClick = (e) => {
        logAmplitudeEvent("Menu item clicked", {
            ...logProps,
            menuItem: e.alias
        });
        appTransition(e, this.props)
    }

    onBackArrowClick = () => {
        if (this.isCurrentMode(MODE.VERIFICATION)) {
            this.switchToMode(MODE.INPUT)
        } else if (this.isCurrentMode(MODE.INPUT)) {
            this.props.history.goBack();
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return stateFromProps(nextProps, prevState, logProps)
    }

    handleOnBlur = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        const error = this.validateField(name, val);
        this.setError(name, error);

        if (error) {
            this.showSaveButtonFor(name, false)
        }
        this.showHintMessageFor(name, false)

        if (!this.wasModified(name)){
            this.showEditButtonFor(name, true)
        }
    }

    showSaveButtonFor = (inputName, display) => {
        const fieldName = display ? inputName : ''
        this.setState({
            showSaveButtonFor: fieldName
        })
    }

    setError = (name, error) => {
        this.setState({
            errors: {
                ...this.state.errors,
                [name]: error
            }
        });
    }

    getValue = name => {
        if (this.state[name]) {
            return this.state[name];
        }
        return "";
    };

    componentDidUpdate(prevProps, prevState, snapshot) {

        const userUpdateRequestOccurred = this.props.userUpdateRequestId && this.props.userUpdateRequestId !== prevProps.userUpdateRequestId
        if (this.isCurrentMode(MODE.INPUT) && userUpdateRequestOccurred) {
            const stayOnThisPage = this.props.errorMessage
            if (stayOnThisPage) {
                return;
            }
            if (this.state.accessCodeRequired) {
                this.switchToMode(MODE.VERIFICATION)
            }
            return
        }

        const userChanged = prevProps.user
            && this.props.user
            && prevProps.user.merchantId !== this.props.user.merchantId

        if (userChanged) {
            this.props.history.push("/user");
            return
        }

        if (this.isCurrentMode(MODE.VERIFICATION)) {
            let modifiedFieldName = this.state.showSaveButtonFor;
            if (!modifiedFieldName) {
                return
            }

            const fieldWasSuccessfullyUpdatedPrev = prevProps.fieldUpdateStatus.user[modifiedFieldName]
            const fieldWasSuccessfullyUpdated = this.props.fieldUpdateStatus.user[modifiedFieldName]
            if (!fieldWasSuccessfullyUpdatedPrev && fieldWasSuccessfullyUpdated) {
                appTransition({alias: "user"}, this.props)
            }
        }
    }

    subscribedCheckbox = (checked) => {
        return checked ? <img src={checkbox_v} alt={"checkbox_v.svg"}/> : <img src={checkbox} alt={"checkbox.svg"}/>
    }

    onSubscribedChange = () => {
        let e = {target: {name: 'subscribed', value: !this.state.subscribed}}
        this.handleOnChange(e)
    }

    componentDidMount() {

        if (!this.props.user) {
            this.props.history.push("/user");
        }

        verifyWisetackToken(this.props)
        window.scrollTo(0, 0);

        let merchantId;
        if (this.props.merchant) {
            merchantId = this.props.merchant.id;
        } else {
            merchantId = localStorage.getItem("merchant:id");
            if (merchantId) {
                this.props.getMerchant(merchantId);
            }
        }

        if (!merchantId) {
            redirectToEntryPage(this.props, 'Merchant ID not found.')
            return;
        }

        logProps.merchantId = merchantId;
        if (this.props.user) {
            logProps.userId = this.props.user.userId;
        }
        logAmplitudeEvent(pageName, logProps);

        setValidators(logProps)
        this.props.setError(null)
    }

    render() {

        const formInput = (fieldName) => {

            return (
                <>
                    <FormInput
                        name={fieldName}
                        label={formFields[fieldName].label}
                        value={this.getValue(fieldName)}
                        onFocus={this.handleOnFocus}
                        onChange={this.handleOnChange}
                        onBlur={this.handleOnBlur}
                        errors={this.state.errors}
                        inputMode={formFields[fieldName].inputMode}
                        pattern={formFields[fieldName].pattern}
                        innerRef={formFields[fieldName].innerRef}
                        fieldsError={this.props.fieldsError}
                        suggestions={this.state.suggestions}
                        hintLabel={this.state.showHintMessageFor[fieldName] ? formFields[fieldName].hintLabel : ''}
                    />
                    {this.state.showEditButtonFor[fieldName] &&
                    <img name={fieldName} src={edit} alt={"edit.svg"} onClick={this.handleOnPenClick}/>
                    }
                    {this.state.showSaveButtonFor === fieldName &&
                    <ButtonFilled onClick={this.handleSave}>SAVE CHANGES</ButtonFilled>
                    }

                </>
            )
        }

        const checkboxInput = (fieldName) => {
            return (
                <>
                    <label style={{color: "#6f7595"}} className="bmd-label-floating">
                        {formFields[fieldName].label}
                    </label>
                    <div className={styles.subscription} onClick={this.onSubscribedChange}>
                        {this.subscribedCheckbox(this.state.subscribed)}
                        <div className={styles.subscription_text}>
                            {formFields[fieldName].checkboxText}
                        </div>
                    </div>
                    {this.state.showSaveButtonFor === fieldName &&
                    <ButtonFilled onClick={this.handleSave}>SAVE CHANGES</ButtonFilled>
                    }
                </>
            )
        }

        return (
            <Container>
                <NavBar title={"My profile"}
                        users={this.state.users}
                        onMenuItemClick={this.handleMenuItemClick}
                        onBackArrowClick={this.onBackArrowClick}
                />
                <div className={styles.header}>
                    {this.isCurrentMode(MODE.INPUT) &&
                    <div className={styles.title}>Edit the fields below to update your information</div>
                    }
                    {this.isCurrentMode(MODE.VERIFICATION) && !this.props.isLoading &&
                    <>
                        <div className={styles.title}>{formFields[this.state.showSaveButtonFor].verificationTitle}</div>
                        <div className={styles.subtitle}>Please enter the verification code we sent
                                    to <b>{this.state[this.state.showSaveButtonFor]}</b></div>
                    </>
                    }
                </div>
                <div className={styles.error_banner_wrapper}>
                    <ErrorWithIcon>{this.props.errorMessage}</ErrorWithIcon>
                </div>
                <LoaderWithMessage loading={this.props.isLoading}/>
                <div className={styles.content}>
                    {this.isCurrentMode(MODE.INPUT) && !this.props.isLoading &&
                    <>
                        <FormRow>{formInput('firstName')}</FormRow>
                        <FormRow>{formInput('lastName')}</FormRow>
                        <FormRow>{formInput('phoneNumber')}</FormRow>
                        <FormRow>{formInput('email')}</FormRow>
                        <FormRow>{checkboxInput('subscribed')}</FormRow>
                    </>
                    }
                    {this.isCurrentMode(MODE.VERIFICATION) && !this.props.isLoading &&
                    <>
                        <FormRow>
                            <FormInput name='accessCode' inputMode='numeric' pattern="[0-9]*" label='4-digit code'
                                       value={this.state.accessCode} onChange={this.handleOnAccessCodeChange}
                                       errors={this.state.errors} fieldsError={this.props.fieldsError}
                            />
                        </FormRow>
                    </>
                    }
                </div>
                {this.isCurrentMode(MODE.VERIFICATION) && !this.props.isLoading &&
                <>
                    <div data-toggle="modal" data-target="#getCodeModal">
                        <div className={styles.text_button}>
                            Didn't get a code?
                        </div>
                    </div>
                    <Modal id={'getCodeModal'}>
                        <div className={styles.modal_text}>
                            <img className={styles.logo} src={info} alt="info.svg"/>
                            <h4>Didn't get a code?</h4>
                            <div>
                                Check and make sure the information you entered is correct. Then resubmit your changes
                                to receive a new verification code.
                            </div>
                        </div>
                    </Modal>
                </>
                }
            </Container>
        )
    }
}

const setPropFromMerchantState = (props, state, name, path) => {
    if (path) {
        state = state.merchant[path];
    } else {
        state = state.merchant
    }
    if (state) {
        props[name] = state[name];
    }
};

const setPropFromSettingsState = (props, state, name, path) => {
    if (path) {
        state = state.settings[path];
    } else {
        state = state.settings
    }
    if (state) {
        props[name] = state[name];
    }
};

const mapStateToProps = (state) => {
    let props = {};

    Object.values(formFields)
        .map(v => v.stateName)
        .forEach(name => setPropFromMerchantState(props, state, name, 'user'))

    merchantStateNames.forEach(name => setPropFromMerchantState(props, state, name));
    settingsStateNames.forEach(name => setPropFromSettingsState(props, state, name));
    return props;
};

export default connect(
    mapStateToProps,
    {
        logOut,
        getMerchant,
        setMerchantUser,
        updateUser,
        setError
    }
)(UserEditPage);