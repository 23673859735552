import React from "react";
import styles from "./PayoutModal.module.scss";
import classNames from "classnames";
import payoutImage from "../assets/img/payout.svg";
import Form from "@wisetack/shared-ui/components/Form";
import FormRow from "@wisetack/shared-ui/components/FormRow";
import FormNumberInput from "@wisetack/shared-ui/components/FormNumberInput";
import Modal from "@wisetack/shared-ui/components/Modal";
import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";

const fieldTemplates = {
    transactionAmount: () => {
        return {
            name: 'transactionAmount',
            label: 'Total loan amount',
            validatorName: 'validateTransactionAmount',
            type: 'number',
            inputMode: 'decimal',
            pattern: '[0-9]*',
            blacklist: /^0+/g,
            prefix: '$'
        };
    },
    payout1Percents: () => {
        return {
            name: 'payout1Percents',
            label: 'How much is required upfront?',
            validatorName: 'validatePayoutPercents',
            type: 'number',
            inputMode: 'numeric',
            pattern: '[0-9]*',
            blacklist: /^0+/g,
            suffix: '%'
        };
    },
    payout1Amount: () => {
        return {
            name: 'payout1Amount',
            label: 'Payout 1',
            validatorName: 'validateTransactionAmount',
            type: 'number',
            inputMode: 'decimal',
            pattern: '[0-9]*',
            blacklist: /^0+/g
        };
    },
    payout2Amount: () => {
        return {
            name: 'payout2Amount',
            label: 'Payout 2',
            validatorName: 'validateTransactionAmount',
            type: 'number',
            inputMode: 'decimal',
            pattern: '[0-9]*',
            blacklist: /^0+/g
        };
    }
}

const pageName = "Payout Modal"
let logProps = {page: pageName}

class PayoutModel extends React.Component {
    state = {
        transactionAmount: "",
        payout1Amount: "",
        payout2Amount: "",
        payout1Percents: "",
        isPayoutSaved: false,
        isTransactionAmountUpdated: false
    }

    constructor(props) {
        super(props);
        this.initializeFormFields()
    }

    initializeFormFields() {
        this.fields = {
            transactionAmount: fieldTemplates.transactionAmount(),
            payout1Percents: fieldTemplates.payout1Percents(),
            payout1Amount: fieldTemplates.payout1Amount(),
            payout2Amount: fieldTemplates.payout2Amount(),
        };
        for (const value of Object.values(this.fields)) {
            value.innerRef = React.createRef();
        }
    }

    componentDidMount() {
        logProps.userId = this.props.user.userId;
        logProps.merchantId = this.props.user.merchantId;
    }

    componentDidUpdate() {
        if (!this.state.isTransactionAmountUpdated
            && this.state.transactionAmount !== this.props.transactionAmount) {
            this.setState({
                transactionAmount: this.props.transactionAmount
            })
        }
    }


    handleOnTransactionAmountChange = e => {
        this.setState({
            transactionAmount: e.target.value,
            isTransactionAmountUpdated: true
        });
        this.updatePayoutAmounts(e.target.value, this.state.payout1Percents);
        logAmplitudeEvent("Transaction amount changed", logProps);
    }

    handleOnPayoutPercentsChange = e => {
        this.setState({
            payout1Percents: e.target.value
        });
        this.updatePayoutAmounts(this.state.transactionAmount, e.target.value);
        logAmplitudeEvent("Payout percents changed", logProps);
    }

    updatePayoutAmounts = (transactionAmount, payout1Percents) => {
        if (!transactionAmount || !payout1Percents) {
            return;
        }
        let transactionAmountInt = transactionAmount, payout1PercentsInt = payout1Percents;
        if (transactionAmount) {
            transactionAmountInt = parseInt(transactionAmount.replace(/[$,]/g,''))
        }
        if(isNaN(payout1Percents)) {
            payout1PercentsInt = parseInt(payout1Percents.replace(/[%,]/g,''))
        }
        let coefficient = payout1PercentsInt / 100;
        let payout1 = coefficient * transactionAmountInt;
        let payout2 = (1 - coefficient) * transactionAmountInt;
        this.setState({
            payout1Amount: payout1,
            payout2Amount: payout2
        });
        logProps = {
            ...logProps,
            transactionAmount: transactionAmountInt,
            payout1Percents: payout1PercentsInt,
            payout1Amount: payout1,
            payout2Amount: payout2
        }
    }

    handleSavePayouts = () => {
        this.setState({
            isPayoutSaved: true,
            isTransactionAmountUpdated: false
        });
        this.props.savePayouts(this.state.transactionAmount, this.state.payout1Amount, this.state.payout2Amount);
        logAmplitudeEvent("Save payouts clicked", logProps);
    }

    handleResetPayouts = () => {
        this.setState({
            transactionAmount: "",
            payout1Amount : "",
            payout2Amount : "",
            payout1Percents : "",
            isPayoutSaved: false
        });
        this.props.savePayouts("", "");
        logAmplitudeEvent("Reset payouts clicked", logProps);
    }

    handleOnTransactionAmountBlur = e => {
        this.setState({
            errors: {
                ...this.state.errors,
                transactionAmount: this.validateTransactionAmount(e.target.value),
                payout1Percents: this.validatePayoutPercents(this.state.payout1Percents)
            }
        });
    }

    handleOnPayoutPercentsBlur = e => {
        this.setState({
            errors: {
                ...this.state.errors,
                payout1Percents: this.validatePayoutPercents(e.target.value)
            }
        });
    }

    validatePayoutPercents = value => {
        if (!value) return "";
        const payout1Percents = parseInt(value.replace(/[%,]/g,''));
        if (payout1Percents < 0 || payout1Percents > 50) {
            return "Cannot exceed 50% of the total amount";
        }
        const payout1Amount = parseInt(this.state.payout1Amount);
        if (payout1Amount < this.props.transactionLimit.min) {
            return "Payout 1 must be at least $" + this.props.transactionLimit.min;
        }
        return "";
    };

     validateTransactionAmount = value => {
        if (!value) return "";
        const transactionAmount = parseInt(value.replace(/[$,]/g,''));if (transactionAmount < 1000) {
            return "Payouts must total at least $1,000";
        }
         return "";
    };

    isButtonDisabled = () => {
        for (const [name, value] of Object.entries(this.fields)) {
            if (!value.optional) {
                if (!this.state[name]) {
                    return true;
                }
            }
        }
        return this.validateTransactionAmount(this.state.transactionAmount)
            || this.validatePayoutPercents(this.state.payout1Percents);
    };

    render() {
        let btnDisabled = this.isButtonDisabled();

        const btnClasses = classNames({
            btn: true,
            "btn-block": true,
            "btn-disabled": btnDisabled,
            [styles.buttonDisabled]: btnDisabled,
            [styles.buttonEnabled]: !btnDisabled
        });

        return (
            <Modal id={'payoutModal'}>
                <div className={classNames(styles.modalBody)}>
                    <img className={styles.logo} src={payoutImage} alt="payout.svg"/>
                    <h1 className={styles.title}>
                        Add/edit payouts
                    </h1>
                    <div className={styles.description}>
                        Payouts allow you to receive payment in multiple disbursements (e.g., deposit).
                    </div>
                    <Form>
                        <FormRow>
                            <FormNumberInput
                                name={this.fields.transactionAmount.name}
                                label={this.fields.transactionAmount.label}
                                suffix={this.fields.transactionAmount.suffix}
                                value={this.state.transactionAmount}
                                onChange={this.handleOnTransactionAmountChange}
                                onBlur={this.handleOnTransactionAmountBlur}
                                errors={this.state.errors}
                                inputMode={this.fields.transactionAmount.inputMode}
                                innerRef={this.fields.transactionAmount.innerRef}
                                pattern={this.fields.transactionAmount.pattern}
                                thousandSeparator={true}
                                prefix={this.fields.transactionAmount.prefix}
                                decimalScale={2}
                                allowNegative={false}
                                autoFocus={true}
                            />
                        </FormRow>
                        <FormRow>
                            <FormNumberInput
                                placeholder={'%'}
                                name={this.fields.payout1Percents.name}
                                label={this.fields.payout1Percents.label}
                                suffix={this.fields.payout1Percents.suffix}
                                value={this.state.payout1Percents}
                                onChange={this.handleOnPayoutPercentsChange}
                                onBlur={this.handleOnPayoutPercentsBlur}
                                errors={this.state.errors}
                                inputMode={this.fields.payout1Percents.inputMode}
                                innerRef={this.fields.payout1Percents.innerRef}
                                pattern={this.fields.payout1Percents.pattern}
                                thousandSeparator={true}
                                decimalScale={2}
                                allowNegative={false}
                                autoFocus={true}
                            />
                        </FormRow>
                        <FormRow>
                            <FormNumberInput
                                name={this.fields.payout1Amount.name}
                                label={this.fields.payout1Amount.label}
                                suffix={this.fields.payout1Amount.suffix}
                                value={this.state.payout1Amount}
                                inputMode={this.fields.payout1Amount.inputMode}
                                innerRef={this.fields.payout1Amount.innerRef}
                                pattern={this.fields.payout1Amount.pattern}
                                thousandSeparator={true}
                                prefix={'$'}
                                decimalScale={2}
                                allowNegative={false}
                                readOnly={true}
                                autoFocus={true}
                            />
                            <FormNumberInput
                                name={this.fields.payout2Amount.name}
                                label={this.fields.payout2Amount.label}
                                suffix={this.fields.payout2Amount.suffix}
                                value={this.state.payout2Amount}
                                inputMode={this.fields.payout2Amount.inputMode}
                                innerRef={this.fields.payout2Amount.innerRef}
                                pattern={this.fields.payout2Amount.pattern}
                                thousandSeparator={true}
                                prefix={'$'}
                                decimalScale={2}
                                allowNegative={false}
                                readOnly={true}
                                autoFocus={true}
                            />
                        </FormRow>
                    </Form>
                    <button id="savePayouts" className={btnClasses} onClick={this.handleSavePayouts} data-dismiss="modal" aria-label="Close">
                        SAVE PAYOUTS
                    </button>
                    { this.state.isPayoutSaved &&
                        <p id="resetPayouts" className={styles.resetPayouts} onClick={this.handleResetPayouts} data-dismiss="modal">
                            <span className="material-symbols-outlined">
                            delete
                            </span>
                            Reset - Use single payout
                        </p>
                    }
                </div>
            </Modal>
        )
    }
}

export default PayoutModel;