import {connect} from "react-redux";
import NavBar from "../components/NavBar";
import Container from "../components/Container";
import React from "react";
import user_color from "../assets/img/user_color.svg"
import checkbox_small_v from "@wisetack/assets/img/checkbox_small_v.svg"
import checkbox_small_x from "@wisetack/assets/img/checkbox_small_x.svg"
import styles from "./UserPage.module.scss"
import ButtonOutlined from "../components/ButtonOutlined";
import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";
import {appTransition, redirectToEntryPage} from "../utils/transitions";
import {getMerchant, logOut, setMerchantUser, setError} from "../store/actions/merchantActions";
import LoaderWithMessage from "@wisetack/shared-ui/components/LoaderWithMessage";
import {formatUsPhoneToDomestic} from "@wisetack/shared-ui/src/utils/format";
import {verifyWisetackToken} from "../utils/auth";
import {stateFromProps} from "../utils/state";
import Error from "@wisetack/shared-ui/components/Error";

const stateNames = [
    "isLoading",
    "errorMessage",
    "merchant",
    "users",
    "user"
]

const pageName = "User information page"
const logProps = {page: pageName}

class UserPage extends React.Component {

    state = {
    }

    handleEdit = () => {
        this.props.history.push('/user_edit')
    }

    handleMenuItemClick = (e) => {
        logAmplitudeEvent("Menu item clicked", {
            ...logProps,
            menuItem: e.alias
        });
        appTransition(e, this.props)
    }

    onBackArrowClick = () => {
        this.props.history.goBack();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return stateFromProps(nextProps, prevState, logProps)
    }

    componentDidMount() {
        verifyWisetackToken(this.props)
        window.scrollTo(0, 0);

        let merchantId;
        if (this.props.merchant) {
            merchantId = this.props.merchant.id;
        } else {
            merchantId = localStorage.getItem("merchant:id");
            if (merchantId) {
                this.props.getMerchant(merchantId);
            }
        }

        if (!merchantId) {
            redirectToEntryPage(this.props, 'Merchant ID not found.')
            return;
        }

        logProps.merchantId = merchantId;
        if (this.props.user) {
            logProps.userId = this.props.user.userId;
        }
        logAmplitudeEvent(pageName, logProps);
        this.props.setError(null)
    }

    render() {

        let userFullName
        let formattedPhone
        let email
        let subscribed

        if (this.props.user) {
            userFullName = this.props.user.firstNameEncrypted + ' ' + this.props.user.lastNameEncrypted;
            formattedPhone = formatUsPhoneToDomestic(this.props.user.phoneNumberEncrypted)
            email = this.props.user.emailEncrypted
            subscribed = !!this.props.user.subscribed
        }

        return (
            <Container>
                <NavBar title={"My profile"}
                        users={this.state.users}
                        onMenuItemClick={this.handleMenuItemClick}
                        onBackArrowClick={this.onBackArrowClick}
                />
                <div className={styles.header}>
                    <div className={styles.title}>Your user profile</div>
                    <img src={user_color} alt={"user_color.svg"}/>
                </div>
                <LoaderWithMessage loading={this.props.isLoading}/>
                <Error pageName={pageName}>{this.state.errorMessage}</Error>
                {!this.props.isLoading && this.props.user &&
                <div className={styles.content}>
                    <div className={styles.name_ico}>
                        <span className="material-icons-outlined">account_circle</span>
                    </div>
                    <div className={styles.name}>
                        {userFullName}
                    </div>
                    <div className={styles.phone_ico}>
                        <span className="material-icons-outlined">phone</span>
                    </div>
                    <div className={styles.phone}>
                        {formattedPhone}
                    </div>
                    <div className={styles.email_ico}>
                        <span className="material-icons-outlined">email</span>
                    </div>
                    <div className={styles.email}>
                        {email}
                    </div>
                    <div className={styles.subscribed_ico}>
                        {subscribed && <img src={checkbox_small_v} alt={"checkbox_small_v.svg"}/>}
                        {!subscribed && <img src={checkbox_small_x} alt={"checkbox_small_x.svg"}/>}
                    </div>
                    <div className={styles.subscribed}>
                        {subscribed && "Subscribed to notifications"}
                        {!subscribed && "Not subscribed to notifications"}
                    </div>
                </div>
                }

                {!this.props.isLoading &&
                <ButtonOutlined onClick={this.handleEdit}>EDIT</ButtonOutlined>}

            </Container>
        )
    }
}

const setPropFromState = (props, state, name, path) => {
    if (path) {
        state = state.merchant[path];
    } else {
        state = state.merchant
    }
    if (state) {
        props[name] = state[name];
    }
};

const mapStateToProps = (state) => {
    let props = {};
    stateNames.forEach(name => setPropFromState(props, state, name));
    return props;
};

export default connect(
    mapStateToProps,
    {
        logOut,
        getMerchant,
        setMerchantUser,
        setError
    }
)(UserPage);