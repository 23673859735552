import React, {useEffect, useState} from "react";
import {connect, useSelector} from "react-redux";
import {useHistory} from "react-router";

import Container from "@wisetack/shared-ui/components/Container";
import useApiRequest from "@wisetack/shared-ui/src/utils/useApiRequest";
import Error from "@wisetack/shared-ui/components/Error";

import NavBar from "../components/NavBar";
import TransactionsControl from "../components/TransactionsControl";
import useAppFlow from "../hooks/useAppFlow";
import {getMerchant, getPrequals, setPrequalData} from "../store/actions/merchantActions";
import {countSelected, transactionsFilter} from "../utils/format";
import styles from "./PrequalsPage.module.scss";
import PrequalList from "../components/PrequalList";
import {Loader} from "../components/Loader";
import failIceCreamImage from "@wisetack/assets/img/fail-ice-cream.svg";
import plane from "@wisetack/assets/img/paper-plane.svg";

const pageName = 'Prequals Page'

const NextPageButton = ({disabled, onNextPageClick}) => {
    if (disabled) {
        return null;
    }
    return (
        <div className={styles.nextPage} onClick={onNextPageClick}>
            See more transactions
            <i className="material-icons">keyboard_arrow_down</i>
        </div>
    )
}

const NoPrequalificationsInfo = ({prequalsNotFound, filterSelections}) => {
    if (prequalsNotFound) {
        if (filterSelections) {
            return (
                <div>
                    <img className={styles.noTransactionsImage} src={failIceCreamImage} alt="error-logo" />
                    <div className={styles.noTransactions}>No results</div>
                    <div className={styles.noTransactionsHint}>Try to adjust your filters</div>                </div>
            )
        }
        return (
            <div>
                <img className={styles.noTransactionsImage} src={plane} alt="error-logo" />
                <div className={styles.noTransactions}>No prequalifications yet</div>
                <div className={styles.noTransactionsHint}>Click +NEW to send a link to your customer to see their maximum qualified amount.</div>
            </div>
        );
    }
    return null;
}

function PrequalsPage({getMerchant, getPrequals, setPrequalData}) {
    const history = useHistory();

    const prequals = useSelector(state => state.merchant.prequals);
    const getPrequalsRequestId = useSelector(state => state.merchant.getPrequalsRequestId);
    const fetchedPrequalsNumber = useSelector(state => state.merchant.fetchedPrequalsNumber);
    const prequalFilter = useSelector(state => state.merchant.prequalFilter);
    const prequalFilterId = useSelector(state => state.merchant.prequalFilterId);
    const prequalCursorAfter = useSelector(state => state.merchant.prequalCursorAfter);

    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest('merchant');
    const {merchant, users, user, handleMenuItemClick, logAmplitude, skipAuth} = useAppFlow({getMerchant, newRequest}, pageName)
    const loading = !!requestId && requestInProgress

    const [prequalsNotFound, setPrequalsNotFound] = useState(false);

    const filterSelections = countSelected(prequalFilter)
    const merchantId = !!merchant ? merchant.id : null

    useEffect(() => {
        if (!!prequals || !merchantId) {
            return
        }
        getPrequals(merchantId, prequalFilter, null, skipAuth, newRequest());
        logAmplitude('Prequals list requested', {filter: transactionsFilter(prequalFilter)})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [merchantId, prequalFilterId])

    useEffect(() => {
        if (requestError) {
            logAmplitude('Error', {error: requestError})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestError])

    useEffect(() => {
        if (!!requestId && requestId === getPrequalsRequestId) {
            // prequals request finished successfully
            const totalPrequalsNumber = !!prequals ? prequals.length : 0
            logAmplitude('Prequals list fetched', {totalPrequalsNumber, fetchedPrequalsNumber, prequalCursorAfter})
            if (totalPrequalsNumber === 0) {
                setPrequalsNotFound(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestId, getPrequalsRequestId])

    const handleFilterClick = () => {
        logAmplitude('Filter button clicked')
        history.push("/prequal_filters");
    }

    const handleCreateClick = () => {
        logAmplitude('New prequal button clicked')
        history.push("/new_prequal");
    }

    const handleNextPageClick = () => {
        if (prequalCursorAfter) {
            getPrequals(merchantId, prequalFilter, prequalCursorAfter, skipAuth, newRequest());
            logAmplitude('Prequals next page requested', {filter: transactionsFilter(prequalFilter), cursor: prequalCursorAfter})
        }
    }

    const handlePrequalClick = (item) => {
        logAmplitude('Prequal clicked', {prequalAppId: item.id})
        setPrequalData(item)
        history.push("/prequal");
    }

    return <Container>
        <NavBar title={"Prequalifications"} users={users} onMenuItemClick={handleMenuItemClick}/>
        <div className={styles.subtitle}>Prequal applications</div>
        <TransactionsControl user={user}
                             selectedFiltersCount={filterSelections}
                             handleFilterClick={handleFilterClick}
                             handleCreateClick={handleCreateClick}
                             trxType={"PREQUALIFICATIONS"}/>
        <PrequalList prequals={prequals} onPrequalClick={handlePrequalClick}/>
        { loading ?  <Loader/> : <>
            <NoPrequalificationsInfo prequalsNotFound={prequalsNotFound} filterSelections={filterSelections}/>
            <NextPageButton disabled={!prequals || prequals.length === 0 || !prequalCursorAfter} onNextPageClick={handleNextPageClick}/>
            <Error>{requestError}</Error>
        </>
        }
    </Container>
}

export default connect(null, {getMerchant, getPrequals, setPrequalData})(
    PrequalsPage
);
