"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isNewTransactionAvailable = void 0;
var allowedAliases = []; //deprecated
var isNewTransactionAvailable = function isNewTransactionAvailable(user) {
  if (!user) {
    return false;
  }
  //approved at merchant level or not denied at merchant and approved at partner level.
  return user.transactionsEnabled && (user.merchantMerchantPortalTransactionEnabled === true || user.merchantMerchantPortalTransactionEnabled !== false && (user.partnerMerchantPortalTransactionEnabled === true || allowedAliases.includes(user.partnerAlias) && user.partnerMerchantPortalTransactionEnabled !== false));
};
exports.isNewTransactionAvailable = isNewTransactionAvailable;