import uuid from "uuid";
import {api} from "@wisetack/shared-ui/utils/Api";
import { transactionsFilter } from "../../utils/format"
import axios from "axios";

import {
    API_ERROR_RESPONSE,
    MERCHANT_SUBMIT_USERNAME,
    MERCHANT_SUBMIT_EMAIL,
    MERCHANT_SUBMIT_PIN,
    MERCHANT_SUBMIT_EMAIL_PIN,
    MERCHANT_GET_TRANSACTIONS,
    MERCHANT_EXPORT_TRANSACTIONS,
    MERCHANT_GET_ME,
    MERCHANT_SET_TRANSACTION_DATA,
    MERCHANT_SET_USER,
    MERCHANT_CREATE_TRANSACTION,
    MERCHANT_REMINDER,
    APPLY_FILTER,
    APPLY_PREQUAL_FILTER,
    MERCHANT_LOG_OUT,
    MERCHANT_GET_TRANSACTION,
    CONSUMER_GET_VERTICAL_MESSAGES,
    SIGNUP_ZIP,
    SETTINGS_UPDATE_BUSINESS,
    SETTINGS_UPDATE_USER,
    UPDATE_MERCHANT_BANK_INFO,
    CREATE_LINK_TOKEN,
    ACCEPT_PLAID_TOKEN,
    MERCHANT_ADD_USER,
    MERCHANT_DELETE_USER,
    MERCHANT_GET_USERS,
    MERCHANT_SET_USER_IN_EDIT,
    MERCHANT_UPDATE_USER_BY_ADMIN,
    MERCHANT_API_ERROR,
    MERCHANT_NEW_PREQUAL,
    MERCHANT_GET_PREQUALS,
    MERCHANT_EXPORT_PREQUALS,
    MERCHANT_GET_PREQUAL,
    MERCHANT_SET_PREQUAL_DATA,
    DYNAMIC_USER_FILTER,
    DO_NOTHING
} from "./types"

const LIMIT = 25

const getTransactionsLimit = () => {
    if (window && window.transactionsLimit) {
        return window.transactionsLimit;
    }
    return LIMIT
}

const apiInvoke = async (
    method,
    path,
    data,
    dispatch,
    type,
    payloadUpdater,
    skipLoading,
    requestId
) => {
    try {
        const payload = await api(method, path, data, dispatch, skipLoading, requestId);
        if (payloadUpdater) {
            payloadUpdater(payload);
        }
        dispatch({
            type,
            payload
        });
    } catch (err) {
        const errMessage = err.message;
        dispatch({
            type: API_ERROR_RESPONSE,
            payload: errMessage
        });
        if (requestId) {
            dispatch({
                type: MERCHANT_API_ERROR,
                payload: {requestId, error: err}
            });
        }
    }
};

export const getZip = (zip, zipRequestId) => dispatch => {
    apiInvoke(
        "post",
        `/zip`,
        {
            zip
        },
        dispatch,
        SIGNUP_ZIP,
        (payload) => payload.zipRequestId = zipRequestId,
        true
    );
}


export const getVerticalMessages = (vertical, page) => (dispatch) => {
    apiInvoke(
        "get",
        `/verticals/${vertical}/pages/${page}/messages`,
        {},
        dispatch,
        CONSUMER_GET_VERTICAL_MESSAGES
    )
};

export const submitUsername = (username, requestId, impersonation) => dispatch => {
    let data = { username, impersonation }
    let type = username.includes("@") ? MERCHANT_SUBMIT_EMAIL : MERCHANT_SUBMIT_USERNAME
    apiInvoke(
        "post",
        `/merchants/login`,
        data,
        dispatch,
        type,
        payload => {
            payload.username = username;
            payload.submitRequestId = requestId;
            payload.impersonation = impersonation;
        }
    );
};

export const submitPin = (pin, mobileNumberOrEmail, userId, merchantId, requestId) => dispatch => {
    let data = { code: pin, username: mobileNumberOrEmail, requestOrigin: 'merchantUi' }
    let type = mobileNumberOrEmail.includes("@") ? MERCHANT_SUBMIT_EMAIL_PIN : MERCHANT_SUBMIT_PIN
    apiInvoke(
        "post",
        `/merchants/auth`,
        data,
        dispatch,
        type,
        payload => {
            payload.submitPinRequestId = requestId;
            payload.submitPinUserId = userId;
            payload.submitPinMerchantId = merchantId;
        },
        false,
        requestId
    );
};

export const sessionExpired = (payload) => dispatch => {
    dispatch({ type: MERCHANT_LOG_OUT, payload });
}

export const getPrequals = (merchantId, filter, cursorId, skipAuth, requestId) => dispatch => {
    if (!merchantId) {
        dispatch({
            type: API_ERROR_RESPONSE,
            payload: "Merchant ID not specified to get prequals."
        });
        return;
    }
    if (!requestId) {
        requestId = uuid.v4();
    }
    // GetMerchantPrequalsLambda
    let uri = `/merchants/${merchantId}/prequals?limit=${getTransactionsLimit()}`
    if (filter) {
        uri = uri + transactionsFilter(filter)
    }
    if (cursorId) {
        uri = uri + '&cursorId=' + cursorId
    }
    if (skipAuth) { // TODO: remove after testing
        uri = uri + '&skipAuth=true'
    }
    apiInvoke(
        "get",
        uri,
        null,
        dispatch,
        MERCHANT_GET_PREQUALS,
        payload => {
            payload.requestId = requestId;
            payload.merchantId = merchantId;
            payload.filterId = filter ? filter.filterId : null
        },
        false,
        requestId
    );
}


export const exportPrequals = (merchantId, filter, requestId, cursorId) => dispatch => {
    const EXPORT_LIMIT = 200;
    
    if (!merchantId) {
        dispatch({
            type: API_ERROR_RESPONSE,
            payload: "Merchant ID not specified to get prequals."
        });
        return;
    }
    if (!requestId) {
        requestId = uuid.v4();
    }
    // GetMerchantPrequalsLambda
    let uri = `/merchants/${merchantId}/prequals?limit=${EXPORT_LIMIT}`;
    if (filter) {
        uri = uri + transactionsFilter(filter)
    }
    if (cursorId) {
        uri = uri + '&cursorId=' + cursorId
    }
    apiInvoke(
        "get",
        uri,
        null,
        dispatch,
        MERCHANT_EXPORT_PREQUALS,
        payload => {
            payload.requestId = requestId;
            payload.merchantId = merchantId;
            payload.filterId = filter ? filter.filterId : null
        },
        false,
        requestId
    );
}

export const getPrequal = (merchantId, appId, requestId) => dispatch => {
    if (!merchantId) {
        dispatch({
            type: API_ERROR_RESPONSE,
            payload: "Merchant ID not specified to get prequal."
        });
        return;
    }
    if (!appId) {
        dispatch({
            type: API_ERROR_RESPONSE,
            payload: "Application ID not specified to get prequal."
        });
        return;
    }
    if (!requestId) {
        requestId = uuid.v4();
    }
    // GetMerchantPrequalsLambda
    let uri = `/merchants/${merchantId}/prequals?appId=${appId}`
    apiInvoke(
        "get",
        uri,
        null,
        dispatch,
        MERCHANT_GET_PREQUAL,
        payload => {
            payload.requestId = requestId;
            payload.merchantId = merchantId;
        },
        false,
        requestId
    );
}

export const warmUpLambda = (uri)  => dispatch => {

    const uriWithParam = uri + '?warmUp=true' 
    const skipLoading = true;
    apiInvoke(
        'get',
        uriWithParam,
        null,
        dispatch,
        DO_NOTHING,
        null,
        skipLoading,
    );

}

export const getTransactions = (merchantId, filter, cursorId, requestId) => dispatch => {
    if (!merchantId) {
        dispatch({
            type: API_ERROR_RESPONSE,
            payload: "Merchant ID not specified to get transactions."
        });
        return;
    }
    if (!requestId) {
        requestId = uuid.v4();
    }
    // GetMerchantTransactionsLambda
    let uri = `/merchants/${merchantId}/transactions?limit=${getTransactionsLimit()}`
    if (filter) {
        uri = uri + transactionsFilter(filter)
    }
    if (cursorId) {
        uri = uri + '&cursorId=' + cursorId
    }
    apiInvoke(
        "get",
        uri,
        null,
        dispatch,
        MERCHANT_GET_TRANSACTIONS,
        payload => {
            payload.getTransactionsRequestId = requestId;
        },
        false,
        requestId
    );
}


export const exportTransactions = (merchantId, filter, requestId, cursorId) => dispatch => {
    const EXPORT_LIMIT = 100;
   
    if (!merchantId) {
        dispatch({
            type: API_ERROR_RESPONSE,
            payload: "Merchant ID not specified to get transactions."
        });
        return;
    }
    if (!requestId) {
        requestId = uuid.v4();
    }
    // GetMerchantTransactionsLambda
    let uri = `/merchants/${merchantId}/transactions?limit=${EXPORT_LIMIT}`
    if (filter) {
        uri = uri + transactionsFilter(filter)
    }
    if (cursorId) {
        uri = uri + '&cursorId=' + cursorId
    }
    apiInvoke(
        "get",
        uri,
        null,
        dispatch,
        MERCHANT_EXPORT_TRANSACTIONS,
        payload => {
            payload.getExportTransactionsRequestId = requestId;
        },
        false,
        requestId
    );
}

export const getTransaction = (merchantId, transactionId) => dispatch => {
    if (!merchantId) {
        dispatch({
            type: API_ERROR_RESPONSE,
            payload: "Merchant ID is not specified to get transaction."
        });
        return;
    }

    if (!transactionId) {
        dispatch({
            type: API_ERROR_RESPONSE,
            payload: "Transaction ID is not specified to get transaction."
        });
        return;
    }

    let uri = `/merchants/${merchantId}/transactions/${transactionId}`

    apiInvoke(
        "get",
        uri,
        null,
        dispatch,
        MERCHANT_GET_TRANSACTION
    );
}

export const getMerchant = (merchantId, requestId) => dispatch => {
    if (!merchantId) {
        dispatch({
            type: API_ERROR_RESPONSE,
            payload: "Merchant ID not specified to get merchant data."
        });
        return;
    }
    if (!requestId) {
        requestId = uuid.v4();
    }
    apiInvoke(
        "get",
        `/merchants/me`,
        null,
        dispatch,
        MERCHANT_GET_ME,
        payload => {
            payload.getMerchantRequestId = requestId;
        },
        false,
        requestId
    );
}

export const updateBusiness = (merchantId, payload) => dispatch => {
    if (!merchantId) {
        dispatch({
            type: API_ERROR_RESPONSE,
            payload: "Merchant ID not specified to update merchant."
        });
        return;
    }

    const updatedFieldName = Object.keys(payload)[0];

    if (!updatedFieldName) {
        dispatch({
            type: API_ERROR_RESPONSE,
            payload: "Unknown field to update"
        });
        return;
    }

    let uri = `/internal/merchants/${merchantId}`

    apiInvoke(
        "patch",
        uri,
        payload,
        dispatch,
        SETTINGS_UPDATE_BUSINESS,
        payload => {
            payload.updatedFieldName = updatedFieldName;
        }
    );
}

export const updateUser = (merchantId, userId, payload) => dispatch => {
    if (!merchantId) {
        dispatch({
            type: API_ERROR_RESPONSE,
            payload: "Merchant ID not specified to update user."
        });
        return;
    }

    if (!userId) {
        dispatch({
            type: API_ERROR_RESPONSE,
            payload: "User ID not specified to update user."
        });
        return;
    }

    const updatedFieldName = Object.keys(payload)[0];

    if (!updatedFieldName) {
        dispatch({
            type: API_ERROR_RESPONSE,
            payload: "Unknown field to update"
        });
        return;
    }

    let uri = `/merchants/${merchantId}/users/${userId}`

    apiInvoke(
        "patch",
        uri,
        payload,
        dispatch,
        SETTINGS_UPDATE_USER,
        payload => {
            payload.updatedFieldName = updatedFieldName
            payload.userUpdateRequestId = uuid.v4()
        }
    );
}

export const addUser = (merchantId, payload) => dispatch => {
    if (!merchantId) {
        dispatch({
            type: API_ERROR_RESPONSE,
            payload: "Merchant ID not specified to update user."
        });
        return;
    }

    let uri = `/merchants/${merchantId}/users`

    const requestId = uuid.v4();

    apiInvoke(
        "post",
        uri,
        payload,
        dispatch,
        MERCHANT_ADD_USER,
        payload => {
            payload.addUserRequestId = requestId;
        }
    );
}

export const getMerchantUsers = (merchantId) => dispatch => {
    if (!merchantId) {
        dispatch({
            type: API_ERROR_RESPONSE,
            payload: "Merchant ID not specified to update user."
        });
        return;
    }

    let uri = `/merchants/${merchantId}/users`

    apiInvoke(
        "get",
        uri,
        null,
        dispatch,
        MERCHANT_GET_USERS
    );

}

export const loadDynamicUserFilter = (merchantId, requestId) => dispatch => {
    if (!merchantId) {
        dispatch({
            type: API_ERROR_RESPONSE,
            payload: "Merchant ID not specified to update user."
        });
        return;
    }

    let uri = `/merchants/${merchantId}/users`

    apiInvoke(
        "get",
        uri,
        null,
        dispatch,
        DYNAMIC_USER_FILTER,
        payload => {
            payload.merchantId = merchantId;    
        },
        false,
        requestId
    );

}

export const createTransaction = (transaction, requestId) => dispatch => {
    if (!transaction || !transaction.merchantId) {
        dispatch({
            type: API_ERROR_RESPONSE,
            payload: "Transaction data not specified to create transaction."
        });
        return;
    }
    if (!requestId) {
        requestId = uuid.v4();
    }
    const merchantId = transaction.merchantId;
    delete transaction.merchantId;
    apiInvoke(
        "post",
        `/merchants/${merchantId}/transactions`,
        transaction,
        dispatch,
        MERCHANT_CREATE_TRANSACTION,
        payload => {
            payload.createTransactionRequestId = requestId;
            payload.newTransaction = transaction;
        }
    );
}

export const sendPaymentReminder = (transactionId, merchantId, userId, action, requestId) => dispatch => {
    if (!action || (action !== 'CREATE' && action !=='GET')) {
        dispatch({
            type: API_ERROR_RESPONSE,
            payload: "Invalid action specified for payment request."
        });
        return;
    }
    if (!transactionId) {
        dispatch({
            type: API_ERROR_RESPONSE,
            payload: "Transaction ID not specified for payment request."
        });
        return;
    }
    if (!merchantId) {
        dispatch({
            type: API_ERROR_RESPONSE,
            payload: "Merchant ID not specified for payment request."
        });
        return;
    }
    if (!userId) {
        dispatch({
            type: API_ERROR_RESPONSE,
            payload: "User ID not specified for payment request."
        });
        return;
    }
    if (!requestId) {
        requestId = uuid.v4();
    }
    apiInvoke(
        "post",
        `/reminders`,
        {
            action,
            transactionId,
            merchantId,
            userId 
        },
        dispatch,
        MERCHANT_REMINDER,
        payload => {
            payload.reminderRequestId = requestId;
        }
    );
}

export const submitRefund = (loanId, requestId) => dispatch => {
    if (!loanId) {
        dispatch({
            type: API_ERROR_RESPONSE,
            payload: "Loan ID not specified to submit refund."
        });
        return;
    }
    if (!requestId) {
        requestId = uuid.v4();
    }
    dispatch({
        type: API_ERROR_RESPONSE,
        payload: "Sorry, not implemented yet."
    });
}

export const applyFilter = (filter, filterId) => dispatch => {
    if (!filterId) {
        filterId = uuid.v4();
    }
    dispatch({
        type: APPLY_FILTER,
        payload: {filter, filterId}
    });
}

export const applyPrequalFilter = (filter, filterId) => dispatch => {
    if (!filterId) {
        filterId = uuid.v4();
    }
    filter.filterId = filterId;
    dispatch({
        type: APPLY_PREQUAL_FILTER,
        payload: {filter, filterId}
    });
}

export const setTransactionData = (item) => dispatch => {
    dispatch({ type: MERCHANT_SET_TRANSACTION_DATA, payload: item });
};

export const setPrequalData = (item) => dispatch => {
    dispatch({ type: MERCHANT_SET_PREQUAL_DATA, payload: item });
}

export const setMerchantUser = (user) => dispatch => {
    dispatch({ type: MERCHANT_SET_USER, payload: user });
}

export const setError = (payload) => dispatch => {
    dispatch({ type: API_ERROR_RESPONSE, payload });
}

export const logOut = (payload) => dispatch => {
    dispatch({ type: MERCHANT_LOG_OUT, payload });
}

export const setUserInEdit = (user) => dispatch => {
    dispatch({type: MERCHANT_SET_USER_IN_EDIT, payload: user})
}

export const removeMerchantUser = (merchantId, userId) => dispatch => {
    if (!merchantId) {
        dispatch({
            type: API_ERROR_RESPONSE,
            payload: "Merchant ID not specified to delete user."
        });
        return;
    }

    if (!userId) {
        dispatch({
            type: API_ERROR_RESPONSE,
            payload: "User ID not specified to delete user."
        });
        return;
    }

    let uri = `/merchants/${merchantId}/users/${userId}`

    const requestId = uuid.v4();

    apiInvoke(
        "delete",
        uri,
        null,
        dispatch,
        MERCHANT_DELETE_USER,
        payload => {
            payload.deleteUserRequestId = requestId;
            payload.merchantId = merchantId;
        }
    );
}

export const updateMerchantUser = (merchantId, userId, payload) => dispatch => {
    if (!merchantId) {
        dispatch({
            type: API_ERROR_RESPONSE,
            payload: "Merchant ID not specified to update user."
        });
        return;
    }

    if (!userId) {
        dispatch({
            type: API_ERROR_RESPONSE,
            payload: "User ID not specified to update user."
        });
        return;
    }

    let uri = `/merchants/${merchantId}/users/${userId}`

    apiInvoke(
        "patch",
        uri,
        payload,
        dispatch,
        MERCHANT_UPDATE_USER_BY_ADMIN,
        payload => {
            payload.updateMerchantUserRequestId = uuid.v4();
            payload.merchantId = merchantId;
            payload.userId = userId;
        }
    );
}

export const checkRoutingNumber = async (routingNumber) => {
    const accessToken = sessionStorage.getItem("wisetack:ba:token");
    return await axios({
        method: "post",
        url: `${window._wtenv_?.REACT_APP_API_URL}/routingnumber`,
        data: {
            routingNumber,
        },
        headers: {"Authorization": accessToken}
    }).then((response) => {
        return response.data;
    });
};

export const createLinkToken = (merchantId, accountId, requestId) => dispatch => {
    let data = {merchantId}
    if (accountId) {
        data.accountId = accountId
    } else {
        data.plaidProducts = ['AUTH']
    }
    let redirectUri = window.location.origin
    if (redirectUri && (redirectUri.startsWith("https:") || redirectUri.includes('localhost'))) {
        // only https allowed for non localhost,
        // we need this for development/test environments, OAuth will not work on these environments
        data.redirectUri = redirectUri;
    }
    apiInvoke(
        "post",
        `/linktoken`,
        data,
        dispatch,
        CREATE_LINK_TOKEN,
        (payload) => {
            payload.linkToken.requestId = requestId;
            if (!accountId) {
                payload.accountId = 'flexible'
                payload.merchantId = merchantId
            }
        },
        false,
        requestId
    );
}

export const acceptPlaidToken = (
    merchantId,
    token,
    accounts,
    requestId,
    addAnother
) => dispatch => {
    if (!merchantId) {
        dispatch({
            type: API_ERROR_RESPONSE,
            payload: "Merchant ID not specified to link bank"
        });
        return;
    }
    apiInvoke(
        "post",
        `/signups/null/paymentaccounts`,
        {
            merchantId,
            plaidToken: token,
            paymentAuthorizationAccepted: true,
            accounts,
            addAnother
        },
        dispatch,
        ACCEPT_PLAID_TOKEN,
        (payload) => payload.requestId = requestId,
        false,
        requestId
    );
};

export const updateMerchant = (merchantId, data) => dispatch => {
    if (!merchantId) {
        dispatch({
            type: API_ERROR_RESPONSE,
            payload: "Merchant ID not specified to update user."
        });
        return;
    }

    const updatedFieldName = Object.keys(data)[0];

    if (!updatedFieldName) {
        dispatch({
            type: API_ERROR_RESPONSE,
            payload: "Unknown field to update"
        });
        return;
    }

    let uri = `/internal/merchants/${merchantId}`

    apiInvoke(
        "patch",
        uri,
        data,
        dispatch,
        UPDATE_MERCHANT_BANK_INFO,
        (payload) => payload.merchantId = merchantId
    );
}

export const sendPrequalLink = (merchantId, payload, requestId) => dispatch => {
    if (!merchantId) {
        dispatch({
            type: MERCHANT_API_ERROR,
            payload: {requestId, error: {message: "Merchant ID not specified to send prequal link."}}
        });
        return;
    }

    if (!payload || !payload.mobileNumber) {
        dispatch({
            type: MERCHANT_API_ERROR,
            payload: {requestId, error: {message: "Mobile number not specified to send prequal link."}}
        });
        return;
    }

    const uri = `/merchants/${merchantId}/prequallinks`
    apiInvoke(
        'post',
        uri,
        payload,
        dispatch,
        MERCHANT_NEW_PREQUAL,
        payload => {
            payload.requestId = requestId
        },
        false,
        requestId
    );
}
