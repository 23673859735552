import React from "react";
import styles from "./Transaction.module.scss";
import { 
    formatDate, 
    formatBadgeText,
    formatBadgeStyle,
    formatBorrowerName, 
    formatAmount,
    getLastChangedStatus
} from "../utils/format";

export function Transaction({ item, onClick }) {

    const lastChangedStatusInfo = getLastChangedStatus(item);
    const statusDate = lastChangedStatusInfo.statusChangedOn ? lastChangedStatusInfo.statusChangedOn: item.initiatedDate;
    const displayDate = formatDate(statusDate);
    const badgeText = formatBadgeText(lastChangedStatusInfo.statusName, lastChangedStatusInfo.payoutName);
    const name = formatBorrowerName(item.consumer);
    const amount = formatAmount(item);
    const badgeStyle = formatBadgeStyle(lastChangedStatusInfo.statusName, styles);

    return (
        <div className={styles.transaction} onClick={() => onClick(item)}>
            <table>
                <tbody>
                    <tr>
                        <td className={styles.name}>{name}</td>
                        <td className={styles.amount}>{amount}</td>
                    </tr>
                    <tr>
                        <td className={styles.status}>
                            <span className={badgeStyle}>{badgeText}</span>
                        </td>
                        <td className={styles.date}>{displayDate}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
