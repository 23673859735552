import React, {useEffect} from "react";
import {useHistory} from "react-router";
import {connect, useSelector} from "react-redux";
import classNames from "classnames";
import {useFormik} from "formik";
import * as Yup from 'yup';
import faker from "faker/locale/en_US";

import LoaderWithMessage from "@wisetack/shared-ui/components/LoaderWithMessage";
import Error from "@wisetack/shared-ui/components/Error";
import Form from "@wisetack/shared-ui/components/Form";
import FormRow from "@wisetack/shared-ui/components/FormRow";
import FormInput from "@wisetack/shared-ui/components/FormInput";
import useApiRequest from "@wisetack/shared-ui/src/utils/useApiRequest";
import {formatUsPhone} from "@wisetack/shared-ui/utils/format";

import {getMerchant, sendPrequalLink} from "../store/actions/merchantActions";
import useAppFlow from "../hooks/useAppFlow";
import Container from "../components/Container";
import NavBar from "../components/NavBar";
import styles from "./NewPrequalPage.module.scss";

const pageName = 'New Prequal Page'

const validateMobileNumber = mobileNumber => {
    if (!mobileNumber) return false;
    const number = mobileNumber.replace(/-/g, "");
    return (number.length === 10 || (number.length === 11 && number[0] === "1"));
};

const formatMobileNumber = mobileNumber => {
    mobileNumber = mobileNumber.replace(/\D/g, "");
    if (mobileNumber.length === 10) {
        return "+1" + mobileNumber;
    } else if (mobileNumber.length === 11) {
        return "+" + mobileNumber;
    }
    return mobileNumber;
};

const validationSchema = Yup.object().shape({
    mobileNumber: Yup.string().required('Enter mobile number').test('mobile-number', 'Enter valid mobile number', validateMobileNumber)
})

function NewPrequalPage({getMerchant, sendPrequalLink}) {
    const history = useHistory();
    const newPrequalRequestId = useSelector(state => state.merchant.newPrequalRequestId);
    const prequalLink = useSelector(state => state.merchant.prequalLink);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest('merchant');
    const {merchant, user, users, handleMenuItemClick, logAmplitude} = useAppFlow({getMerchant, newRequest}, pageName)
    const loading = !!requestId && requestInProgress

    useEffect(() => {
        if (!!prequalLink && !!newPrequalRequestId && newPrequalRequestId === requestId) {
            logAmplitude('New prequal link created', {prequalLink});
            history.push("/prequal_sent")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newPrequalRequestId, prequalLink, requestId])

    const handleOnSubmit = (values) => {
        if (merchant && values) {
            const payload = {
                mobileNumber: formatMobileNumber(values.mobileNumber),
                initiatorUserId: user.userId
            }
            if (values.transactionPurpose) {
                payload.transactionPurpose = values.transactionPurpose
            }
            sendPrequalLink(merchant.id, payload, newRequest())
            logAmplitude('New prequal link requested');
        }
    }

    const formik = useFormik({
        initialValues: {
            mobileNumber: '',
            transactionPurpose: ''
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: handleOnSubmit
    });
    const btnDisabled = !formik.dirty || !formik.isValid

    const btnClasses = classNames({
        btn: true,
        "btn-block": true,
        "btn-disabled": btnDisabled,
        [styles.buttonDisabled]: btnDisabled,
        [styles.buttonEnabled]: !btnDisabled
    });

    return <Container>
        <NavBar title={"New prequalification"} users={users} onMenuItemClick={handleMenuItemClick}/>
        <div className={styles.subtitle}>See if your customer prequalifies</div>
        <LoaderWithMessage loading={loading}/>
        {!loading && <>
            <Form>
                <FormRow>
                    <FormInput
                        name="mobileNumber"
                        label="Customer mobile number"
                        formik={formik}
                        blacklist={/[^0-9]/g}
                        autoFill='5555555555'
                        autoFormat={formatUsPhone}
                    />
                </FormRow>
                <FormRow>
                    <FormInput
                        name="transactionPurpose"
                        label="Service description (optional)"
                        formik={formik}
                        blacklist={/[^a-zA-Z0-9\s/'"&#=:;,.+_!%)(|-]/g}
                        autoFill={faker.commerce.productName}
                        type="text"
                    />
                </FormRow>
            </Form>
            <Error>{requestError}</Error>
            <div
                className="row"
                style={{ paddingTop: "15px" }}
            >
                <div className="col">
                    <button
                        type="submit"
                        data-test-id="continue"
                        className={btnClasses}
                        onClick={formik.handleSubmit}
                    >
                        SEND PREQUAL APPLICATION
                    </button>
                </div>
            </div>
        </>
        }
    </Container>
}

export default connect(null, {getMerchant, sendPrequalLink})(
    NewPrequalPage
);
