import React from "react";
import styles from "./UserEditSettingsPage.module.scss"
import Container from "../components/Container";
import NavBar from "../components/NavBar";
import ButtonFilled from "../components/ButtonFilled";
import {connect} from "react-redux";
import {
    getMerchant,
    logOut,
    setError,
    setMerchantUser,
    updateMerchantUser,
    removeMerchantUser
} from "../store/actions/merchantActions";
import {formatUsPhoneToDomestic} from "@wisetack/shared-ui/utils/format";
import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";
import {appTransition, redirectToEntryPage} from "../utils/transitions";
import {stateFromProps} from "../utils/state";
import {isAdmin, verifyWisetackToken} from "../utils/auth";
import LoaderWithMessage from "@wisetack/shared-ui/components/LoaderWithMessage";
import ErrorWithIcon from "@wisetack/shared-ui/components/ErrorWithIcon";
import ButtonOutlined from "../components/ButtonOutlined";
import bin_img from "@wisetack/assets/img/bin.svg"
import checkbox_v from "@wisetack/assets/img/checkbox_v.svg"
import checkbox from "@wisetack/assets/img/checkbox.svg"
import FormRadioButton from "@wisetack/shared-ui/components/FormRadioButton";
import {USER_ROLE_DISPLAY_MAPPING} from "../utils/format";

const stateNames = [
    "isLoading",
    "users",
    "merchant",
    "user",
    "errorMessage",
    "userInEdit",
    "deleteUserRequestId",
    "updateMerchantUserRequestId"
]

const pageName = "User edit settings page"
const logProps = {page: pageName}

const formFields = {
    userRole: {},
    subscribed: {}
}

/*
    modes flow
    EDIT -> SUCCESSFUL_EDIT
    EDIT -> REMOVE_CONFIRMATION -> SUCCESSFUL_REMOVE
 */

const MODE = {
    EDIT: 'EDIT',
    SUCCESSFUL_EDIT: 'SUCCESSFUL_EDIT',
    REMOVE_CONFIRMATION: 'REMOVE_CONFIRMATION',
    SUCCESSFUL_REMOVE: 'SUCCESSFUL_REMOVE'
}

class UserEditSettingsPage extends React.Component {

    state = {
        originalValues: {
            userRole: this.props.userInEdit ? this.props.userInEdit.userRole : null,
            subscribed: this.props.userInEdit ? !!this.props.userInEdit.subscribed : null,
        },
        errors: {},
        firstNameEncrypted: this.props.userInEdit ? this.props.userInEdit.firstNameEncrypted : null,
        lastNameEncrypted: this.props.userInEdit ? this.props.userInEdit.lastNameEncrypted : null,
        phoneNumberEncrypted: this.props.userInEdit ? formatUsPhoneToDomestic(this.props.userInEdit.phoneNumberEncrypted) : null,
        emailEncrypted: this.props.userInEdit ? this.props.userInEdit.emailEncrypted : null,
        mode: MODE.EDIT,
        removedUserFullName: null,
        userRole: this.props.userInEdit ? this.props.userInEdit.userRole : null,
        subscribed: this.props.userInEdit ? !!this.props.userInEdit.subscribed : null,
    }

    handleUpdate = () => {
        const payload = this.preparePayload();
        this.props.updateMerchantUser(this.props.merchant.id, this.props.userInEdit.userId, payload)
    }

    handleRemoveUser = () => {
        this.switchToMode(MODE.REMOVE_CONFIRMATION)
    }

    preparePayload = () => {

        const payload = {}

        for (const key of Object.keys(formFields)) {
            if (this.wasModified(key)) {
                payload[key] = this.state[key];
            }
        }
        return payload
    }

    switchToMode = (mode) => {
        this.setState({mode: mode})
        this.props.setError(null)
    }

    isCurrentMode = (mode) => {
        return this.state.mode === mode;
    }

    wasModified = (inputName) => {

        const originalValue = this.state.originalValues[inputName];
        const actualValue = this.state[inputName];

        return originalValue !== actualValue
    }

    handleMenuItemClick = (e) => {
        logAmplitudeEvent("Menu item clicked", {
            ...logProps,
            menuItem: e.alias
        });
        appTransition(e, this.props)
    }

    onBackArrowClick = () => {
        if (this.isCurrentMode(MODE.REMOVE_CONFIRMATION)) {
            this.switchToMode(MODE.EDIT)
        } else if (this.isCurrentMode(MODE.SUCCESSFUL_REMOVE)) {
            this.props.history.goBack();
        } else if (this.isCurrentMode(MODE.EDIT)) {
            this.props.history.goBack();
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return stateFromProps(nextProps, prevState, logProps)
    }

    setError = (name, error) => {
        this.setState({
            errors: {
                ...this.state.errors,
                [name]: error
            }
        });
    }

    getValue = name => {
        if (this.state[name]) {
            return this.state[name];
        }
        return "";
    };

    isUpdateButtonEnabled = () => {
        if (this.props.isLoading) return false;

        for (const key in this.state.errors) {
            if (this.state.errors[key]) return false;
        }

        for (const key of Object.keys(formFields)) {
            if (this.wasModified(key)) {
                return true;
            }
        }

        return false;
    };

    componentDidUpdate(prevProps, prevState, snapshot) {

        const userChanged = prevProps.user
            && this.props.user
            && prevProps.user.merchantId !== this.props.user.merchantId

        if (userChanged) {
            this.props.history.push("/user_management");
            return
        }

        if (this.isCurrentMode(MODE.REMOVE_CONFIRMATION)) {

            const userSuccessfullyDeleted = this.props.deleteUserRequestId
                && this.props.deleteUserRequestId !== prevProps.deleteUserRequestId
                && !this.props.errorMessage

            if (userSuccessfullyDeleted) {
                this.switchToMode(MODE.SUCCESSFUL_REMOVE);
                return
            }
        }

        if (this.isCurrentMode(MODE.EDIT)) {

            const userSuccessfullyEdited = this.props.updateMerchantUserRequestId
                && this.props.updateMerchantUserRequestId !== prevProps.updateMerchantUserRequestId
                && !this.props.errorMessage

            if (userSuccessfullyEdited) {
                this.props.history.push("/user_management")
            }
        }
    }

    componentDidMount() {

        if (!isAdmin(this.props.user)) {
            this.props.history.push("/account_settings");
            return;
        }

        if (!this.props.userInEdit) {
            this.props.history.push("/user_management");
            return;
        }

        verifyWisetackToken(this.props)
        window.scrollTo(0, 0);

        let merchantId;
        if (this.props.merchant) {
            merchantId = this.props.merchant.id;
        } else {
            merchantId = localStorage.getItem("merchant:id");
            if (merchantId) {
                this.props.getMerchant(merchantId);
            }
        }

        if (!merchantId) {
            redirectToEntryPage(this.props, 'Merchant ID not found.')
            return;
        }

        logProps.merchantId = merchantId;
        if (this.props.user) {
            logProps.userId = this.props.user.userId;
        }
        logAmplitudeEvent(pageName, logProps);

        this.props.setError(null)
    }

    onRemoveConfirmation = () => {
        this.setState({removedUserFullName: this.props.userInEdit.firstNameEncrypted + " " + this.props.userInEdit.lastNameEncrypted})
        this.props.removeMerchantUser(this.props.merchant.id, this.props.userInEdit.userId)
    }

    onRemoveCancel = () => {
        this.switchToMode(MODE.EDIT)
    }

    onUserRoleChange = (clickedRole) => {
        this.setState({
            userRole: clickedRole
        });
    }

    onSubscribedChange = () => {
        this.setState({
            subscribed: !this.state.subscribed
        });
    }

    subscribedCheckbox = (checked) => {
        return checked ? <img src={checkbox_v} alt={"checkbox_v.svg"}/> : <img src={checkbox} alt={"checkbox.svg"}/>
    }

    render() {

        return (
            <Container>
                <NavBar title={"Edit user"}
                        users={this.state.users}
                        onMenuItemClick={this.handleMenuItemClick}
                        onBackArrowClick={this.onBackArrowClick}
                />
                <div className={styles.header}>
                    {this.isCurrentMode(MODE.EDIT) &&
                    <div className={styles.title}>Update user settings</div>
                    }
                    {this.isCurrentMode(MODE.SUCCESSFUL_REMOVE) &&
                    <div className={styles.title}>Successfully removed!</div>
                    }
                </div>
                <div className={styles.error_wrapper}>
                    <ErrorWithIcon>{this.props.errorMessage}</ErrorWithIcon>
                </div>
                <LoaderWithMessage loading={this.props.isLoading}/>
                <div className={styles.content}>
                    {this.isCurrentMode(MODE.EDIT) && !this.props.isLoading &&
                    <>
                        <div className={styles.user_info}>
                            <div className={styles.name_ico}>
                                <span className="material-icons-outlined">account_circle</span>
                            </div>
                            <div className={styles.name}>
                                {this.state.firstNameEncrypted} {this.state.lastNameEncrypted}
                            </div>
                            <div className={styles.phone_ico}>
                                <span className="material-icons-outlined">phone</span>
                            </div>
                            <div className={styles.phone}>
                                {this.state.phoneNumberEncrypted}
                            </div>
                            <div className={styles.email_ico}>
                                <span className="material-icons-outlined">email</span>
                            </div>
                            <div className={styles.email}>
                                {this.state.emailEncrypted}
                            </div>
                        </div>

                        <label style={{color: "#6f7595"}} className="bmd-label-floating">
                            {"Permission setting"}
                        </label>
                        <div className={styles.permissions}>
                            <div className={styles.admin}>
                                <FormRadioButton
                                    label={USER_ROLE_DISPLAY_MAPPING['ADMIN']}
                                    checked={'ADMIN' === this.state.userRole}
                                    value={'ADMIN'}
                                    onClick={this.onUserRoleChange}
                                />
                            </div>
                            <div className={styles.standard}>
                                <FormRadioButton
                                    label={USER_ROLE_DISPLAY_MAPPING['STANDARD']}
                                    checked={'STANDARD' === this.state.userRole}
                                    value={'STANDARD'}
                                    onClick={this.onUserRoleChange}
                                />
                            </div>
                        </div>

                        <label style={{color: "#6f7595"}} className="bmd-label-floating">
                            {"Email notification setting"}
                        </label>
                        <div className={styles.subscription} onClick={this.onSubscribedChange}>
                            {this.subscribedCheckbox(this.state.subscribed)}
                            <div className={styles.subscription_text}>
                                {"Receive all loan application updates and weekly transaction reports."}
                            </div>
                        </div>

                        <ButtonFilled onClick={this.handleUpdate} disabled={!this.isUpdateButtonEnabled()}>
                            UPDATE
                        </ButtonFilled>
                        <ButtonOutlined onClick={this.handleRemoveUser}>REMOVE USER</ButtonOutlined>
                    </>
                    }
                    {this.isCurrentMode(MODE.REMOVE_CONFIRMATION) && !this.props.isLoading &&
                    <div style={{textAlign: "center"}}>
                        <img src={bin_img} alt={"bin"} width={"60px"} height={"64px"} style={{marginBottom: "30px"}}/>
                        <div className={styles.confirmation_message}>
                            Are you sure you want to
                            remove <b>{this.state.firstNameEncrypted} {this.state.lastNameEncrypted}</b> from this
                            account?
                        </div>
                        <ButtonFilled onClick={this.onRemoveConfirmation}>YES, REMOVE</ButtonFilled>
                        <ButtonOutlined onClick={this.onRemoveCancel}>NO, CANCEL</ButtonOutlined>
                    </div>
                    }
                    {this.isCurrentMode(MODE.SUCCESSFUL_REMOVE) && !this.props.isLoading &&
                    <div style={{textAlign: "center"}}>
                        <img src={bin_img} alt={"bin"} width={"60px"} height={"64px"} style={{marginBottom: "30px"}}/>
                        <div className={styles.confirmation_message}>
                            <b>{this.state.removedUserFullName}</b> no longer has access to this account.
                            <br/>
                            <br/>
                            You can also invite them again later.
                        </div>
                    </div>
                    }
                </div>
            </Container>
        )
    }
}

const setPropFromState = (props, state, name, path) => {
    if (path) {
        state = state.merchant[path];
    } else {
        state = state.merchant
    }
    if (state) {
        props[name] = state[name];
    }
};

const mapStateToProps = (state) => {
    let props = {};
    stateNames.forEach(name => setPropFromState(props, state, name));
    return props;
};

export default connect(
    mapStateToProps,
    {
        logOut,
        getMerchant,
        setMerchantUser,
        updateMerchantUser,
        setError,
        removeMerchantUser
    }
)(UserEditSettingsPage);