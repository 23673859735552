import moment from "moment"
import { formatUsPhoneToDomestic } from "@wisetack/shared-ui/src/utils/format";

export const USER_ROLE_DISPLAY_MAPPING = {
    'ADMIN': 'Admin',
    'STANDARD': 'Standard'
}

export const customizeErrorMessage = (errorMessage) => {
    if (errorMessage.match('Verification code .* not found.')) {
        return { pin: 'Please enter a valid verification code' }
    }
    if (errorMessage.match("Verification code .* has expired .*")) {
        return { pin: 'Verification code has expired' }
    }
    if (errorMessage.match('User .* not found.')) {
        return { username: 'Please enter an email or phone number associated with your merchant account' }
    }
    if (errorMessage.match('Email address is not verified.*')) {
        return { username: 'Something went wrong...' }
    }
    if (errorMessage.match('The Token has expired .*')) {
        return { token: 'The Token has expired' }
    }
}

export const formatDate = (dateStr) => {
    if (!dateStr || dateStr.length < 10) {
        return '- -'
    }
    dateStr = dateStr.substring(0, 10)
    return moment(dateStr, "YYYY-MM-DD").format("MMM D, YYYY")
}

export const formatBadgeText = (status, payoutName) => {
    if (!status) {
        return ''
    }
    if (payoutName) {
        return payoutName + " - " + status;
    }
    return status;
}

export const formatPrequalStatusInfo = (status) => {
    switch (status) {
        case "PENDING":
        case "INITIATED":
            return [
                "Initiated",
                "Prequalification Initiated",
                "The customer has started the application for prequalification.",
                "initiated.svg"
            ]
        case "EXPIRED":
            return [
                "Expired",
                "Prequalification Expired",
                "Please send the customer another prequalification application.",
                "expired.svg"
            ]
        case "PREQUALIFIED":
            return [
                "Prequalified",
                "Prequalified",
                "The actual amount and terms will vary upon applying for a loan.",
                "prequalified.svg"
            ]
        case "DECLINED":
            return [
                "Declined",
                "Prequalification Declined",
                "The prequalification was declined due to identity or credit reasons.",
                "declined.svg"
            ]
        case "CANCELED":
            return [
                "Canceled",
                "Prequalification Canceled",
                "The customer has canceled their prequalification application.",
                "canceled.svg"
            ]
        default:
            return ['', '', '', '']
    }
}

export const formatBadgeStyle = (status, styles) => {
    if (!status) {
        return styles.default;
    }

    switch (status.toLowerCase()) {
        case "started":
        case "approved":
        case "offer locked":
        case "action required":
            return styles.initiated;
        case "expired":
            return styles.expired;
        case "rejected":
        case "canceled":
        case "declined":
            return styles.declined;
        case "refunded":
            return styles.refunded;
        default:
            return styles.default;
    }
}

export const formatPrequalBadgeStyle = (status, styles) => {
    if (!status) {
        return styles.default;
    }
    switch (status.toLowerCase()) {
        case "pending":
        case "initiated":
            return styles.initiated;
        case "expired":
            return styles.expired;
        case "declined":
            return styles.declined;
        case "canceled":
            return styles.canceled;
        default:
            return styles.default;
    }
}

export const formatBorrowerName = (customer) => {
    if (!customer) {
        return '';
    }
    return customer.fullName || formatUsPhoneToDomestic(customer.phone, false);
}

export const formatFEIN = (fein) => {
    if (!fein) {
        return ''
    }
    return fein.substring(0, 2) + "-" + fein.substring(fein.length - 7)
}

export const formatBorrowerAddress = (customer) => {
    let address = ''
    if (!customer) {
        return address
    }
    if (customer.city) {
        address = customer.city + ", "
    }
    if (customer.state) {
        address = address + customer.state + " "
    }
    if (customer.zip) {
        address = address + customer.zip
    }
    return address.trim()
}

export const formatUserName = (user) => {
    if (!user) {
        return ''
    }
    let name = '';
    if (user.firstNameEncrypted) {
        name = user.firstNameEncrypted + ' ';
    }
    if (user.lastNameEncrypted) {
        name = name + user.lastNameEncrypted + ' ';
    }
    return name.trim();
}

export const formatAmount = (item) => {
    return item.approvedLoanAmount || item.requestedLoanAmount
}

export const formatPrequalAmount = (item, prefix) => {
    if (!item || !item.offerTerms || !item.offerTerms.maxAmount) {
        return ''
    }
    return (prefix || '') + item.offerTerms.maxAmount
}

export const formatExpires = (expires) => {
    if (!expires || expires === 'N/A') {
        return 'N/A'
    }
    return moment(expires, "M/D/YYYY").format("MMM D, YYYY")
}

export const formatRemindAgainAt = (timestamp) => {
    if (!timestamp) {
        return ''
    }
    return moment(timestamp).add(1, 'days').format("[You can request payment again on] MMM D [at] h:mm a")
}

export const countSelected = (obj, names) => {
    let selections = 0;
    if (!obj) {
        return selections
    }
    if (!names) {
        names = Object.keys(obj)
    }
    names.forEach(name => {
        const items = Object.values(obj[name]);
        if (items) {
            items.forEach(item => {
                if (item.selected) {
                    selections++
                }
            })
        }
    })
    return selections
}

export const transactionsFilter = (filter) => {
    if (!filter) {
        return;
    }
    let query = ""
    Object.keys(filter).forEach(key => {
        const items = Object.values({ ...filter[key] });
        let value = "";
        if (key === "months") {
            // sort months in reverse order
            items.sort((a, b) => (a.value < b.value) ? 1 : -1)
        }
        items.forEach(item => {
            if (item.selected) {
                if (value) {
                    value = value + ","
                }
                if (item.value) {
                    value = value + item.value
                } else if (item.text) {
                    value = value + item.text
                }
            }
        })
        if (value) {
            query = `${query}&${key}=${encodeURIComponent(value)}`
        }
    })
    return query
}

export const getLastChangedStatus = (transaction) => {
    if (!transaction.payoutDetailsList || transaction.payoutDetailsList.length < 2) {
        return {
            payoutName : "",
            statusChangedOn: transaction.statusChangedOn,
            status : transaction.status,
            statusName: transaction.status
        }
    }
    const payouts = transaction.payoutDetailsList;

    const lastChangedPayout = payouts.reduce((prev, current) =>
        (prev.statusChangedOn > current.statusChangedOn) ? prev : current);

    let lastChangedStatusInfo = {
        payoutName : lastChangedPayout.payoutName,
        statusChangedOn: lastChangedPayout.statusChangedOn,
        status : lastChangedPayout.status,
        statusName : lastChangedPayout.statusName
    }

    if (isLoanLevelStatus(lastChangedPayout.statusName,
            lastChangedPayout.payoutName === payouts[payouts.length - 1].payoutName)) {
        lastChangedStatusInfo.payoutName = "";
        lastChangedStatusInfo.statusChangedOn = transaction.statusChangedOn;
        lastChangedStatusInfo.status = transaction.status;
        lastChangedStatusInfo.statusName = transaction.status;
    }

    return lastChangedStatusInfo;
}

const isLoanLevelStatus = (status, isLastPayout) => {
    const loanLevelStatuses = ["Started", "Approved", "Declined", "Canceled"];
    if (loanLevelStatuses.includes(status)) {
        return true;
    }

    if (status === "Paid" && isLastPayout) {
        return true;
    }

    return false;
}