import styles from "./TransactionList.module.scss";
import {Transaction} from "./Transaction";
import React from "react";

const SORT_FIELD = 'statusChangedOn';
const SORT_ORDER = 'DESC' // ASC|DESC
const sortDirection = (direction) => {
    return direction === "ASC" ? 1 : -1;
}

class TransactionList extends React.Component{

    render() {
        const transactions = this.props.transactions || [];
        transactions.sort((a, b) => {
            const aDate = new Date(a[SORT_FIELD]);
            const bDate = new Date(b[SORT_FIELD]);
            return (aDate - bDate) * sortDirection(SORT_ORDER);
        })
        return (
            <div className={styles.transactions}>
                {transactions.map(transaction => {
                    return (
                        <Transaction item={transaction} key={transaction.id}
                                     onClick={this.props.handleTransactionClick}/>
                    )
                })}
            </div>
        );
    }
}

export default TransactionList;